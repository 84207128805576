import React, { useState, useEffect } from 'react';
import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Box, Flex, Text } from '@chakra-ui/react';
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import ArrayInput from './ArrayInput';

export default function SkillsModal({ editData, setMainFormData, isOpen, onClose, isEditMode }) {
  const initialFormData = {
    name: '',
    level: '',
    levelNum: 0,
    keywords: []
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isInvalid, setIsInvalid] = useState(true);

  useEffect(() => {
    const newFormData = isEditMode && editData ? editData : initialFormData;
    setFormData(newFormData);
    setIsInvalid(isEditMode ? false : !newFormData.name);
  }, [isEditMode, editData]);

  const handleChange = (e, name) => {
    const value = name ? e : e.target.value;
    const newName = name || e.target.name;
    const newFormData = { ...formData, [newName]: value };
    setFormData(newFormData);
    setIsInvalid(!newFormData.name);
  };

  const handleSubmit = () => {
    if (!isInvalid) {
      setMainFormData(prev => ({ ...prev, skills: isEditMode ? prev.skills.map(s => s === editData ? formData : s) : [...prev.skills, formData] }));
      onClose();
    }
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex alignItems="center">
            {isEditMode ? <EditIcon boxSize={4} /> : <AddIcon boxSize={4} />}
            <Text ml="2">{isEditMode ? 'Edit Skill' : 'Add Skill'}</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"row"} mt={4}>
            {renderInput('Name', 'name', true)}
            {renderInput('Level', 'level', false, 2)}
          </Flex>
          <FormControl mt={4}>
            <FormLabel>Level</FormLabel>
            <Slider value={formData.levelNum} onChange={(e) => handleChange(e, 'levelNum')} min={0} max={10} step={1}>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={6}>
                <Box color="tomato" fontWeight="bold">
                  {formData.levelNum}
                </Box>
              </SliderThumb>
            </Slider>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Keywords</FormLabel>
            <ArrayInput label="Keywords" value={formData.keywords} onChange={(value) => handleChange(value, 'keywords')} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit} isDisabled={isInvalid}>{isEditMode ? 'Edit' : 'Add'}</Button>
          <Button variant="ghost" onClick={()=>onClose()}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  function renderInput(label, name, isRequired = false, ml = 0, type = 'text') {
    return (
      <FormControl isRequired={isRequired} ml={ml} isInvalid={isInvalid && isRequired && !formData[name]}>
        <FormLabel>{label}</FormLabel>
        <Input type={type} name={name} value={formData[name] || ''} onChange={handleChange} />
      </FormControl>
    );
  }
};