import { Box, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { useEffect, useState } from 'react';

function Footer() {
    const [isPageScrollable, setIsPageScrollable] = useState(false);

    useEffect(() => {
        // Check if the document's height exceeds the viewport height
        setIsPageScrollable(document.documentElement.scrollHeight > window.innerHeight);
    }, []);

    const footerStyles = isPageScrollable ? {} : {
        position: "fixed",
        bottom: "0",
        width: "100%"
    };

    return (
        <Box
            {...footerStyles} // Spread the styles
            borderTop={'1px solid rgba(0, 0, 0, 0.1)'}
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}
        >
            <Stack fontSize={{base: 'sm', md: 'md'}} alignItems={'center'} py={'24px'}>
                <Text>© 2023 Easy Resume. All rights reserved</Text>
            </Stack>
        </Box>
    )
}

export default Footer;
