import { React } from "react";
import {
    Box
} from "@chakra-ui/react";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import GetInTouch from "./GetInTouch";

function ContactUs() {
  return (
    <>
        <NavBar />
        <Box>
            <GetInTouch />
        </Box>
        <Footer />
    </>
  );
}

export default ContactUs;