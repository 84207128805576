import { React, useState } from "react";
import {
    Box,
    Button,
    Heading,
    Flex,
    FormControl,
    Spinner,
    FormLabel,
    Input,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    IconButton,
    Text,
    Select
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import TagInput from "./components/TagInput";
import { StepIndicator2 } from "./CoverLetter";
import EducationModal from "./components/resumeModals/EducationModal";
import WorkModal from "./components/resumeModals/WorkModal";
import ProfilesModal from "./components/resumeModals/ProfilesModal";
import AwardsModal from "./components/resumeModals/AwardsModal";
import CertificationsModal from "./components/resumeModals/CertificationsModal";
import PublicationsModal from "./components/resumeModals/PublicationsModal";
import SkillsModal from "./components/resumeModals/SkillsModal";

import { Helmet } from "react-helmet";
import LanguagesModal from "./components/resumeModals/LanguagesModal";
import CustomModal from "./components/resumeModals/CustomModal";

const steps = [
    { title: 'First Step', description: 'Personal Information' },
    { title: 'Second Step', description: 'Professional Background' },
    { title: 'Third Step', description: 'Achievements' },
    { title: 'Fourth Step', description: 'Customizing' },
  ]
  
function StepperBox({activeStep}) {
return (
    <>
    <StepIndicator2 activeStep={activeStep+1}/>
    <Stepper display={{base: 'none', md: 'flex'}} size={'md'} index={activeStep}>
    {steps.map((step, index) => (
        <Step key={index}>
        <StepIndicator>
            <StepStatus
            complete={<StepIcon />}
            incomplete={<StepNumber />}
            active={<StepNumber />}
            />
        </StepIndicator>

        <Box flexShrink='0'>
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
        </Box>

        <StepSeparator />
        </Step>
    ))}
    </Stepper>
    </>
)
}

const Form1 = ({ isError, formData, handleInputChange }) => {
    return (
        <>
            <Heading as="h2" size={{ base: 'md', md: 'lg' }} textAlign="center" mt={'32px'} mb={{ base: '0px', md: '32px' }}>
                Personal Information
            </Heading>
            <Flex flexDirection={{ base: 'column', md: 'row' }}>
                <FormControl isInvalid={isError.fullName} isRequired mr={{ base: '0px', md: '32px' }} mt={{ base: '32px', md: '0px' }}>
                    <FormLabel fontSize={{ base: 'sm', md: 'md' }}>Full Name</FormLabel>
                    <Input type="text" value={formData.fullName} onChange={handleInputChange('fullName')} placeholder="John Doe" />
                </FormControl>
                <FormControl mt={{ base: '32px', md: '0px' }}>
                    <FormLabel fontSize={{ base: 'sm', md: 'md' }}>Email Address</FormLabel>
                    <Input type="email" value={formData.email} onChange={handleInputChange('email')} placeholder="johndoe@email.com" />
                </FormControl>
            </Flex>
            <Flex flexDirection={{ base: 'column', md: 'row' }} mt={{ base: '0px', md: '32px' }}>
                <FormControl mr={{ base: '0px', md: '32px' }} mt={{ base: '32px', md: '0px' }}>
                    <FormLabel fontSize={{ base: 'sm', md: 'md' }}>Phone Number</FormLabel>
                    <Input type="tel" value={formData.phoneNumber} onChange={handleInputChange('phoneNumber')} placeholder="(555) 123-4567" />
                </FormControl>
                <FormControl mt={{ base: '32px', md: '0px' }}>
                    <FormLabel fontSize={{ base: 'sm', md: 'md' }}>Address</FormLabel>
                    <Input type="text" value={formData.address} onChange={handleInputChange('address')} placeholder="123 Elm St, City, Country" />
                </FormControl>
            </Flex>
            <Flex flexDirection={{ base: 'column', md: 'row' }} mt={{ base: '0px', md: '32px' }}>
                <FormControl mr={{ base: '0px', md: '32px' }} mt={{ base: '32px', md: '0px' }}>
                    <FormLabel fontSize={{ base: 'sm', md: 'md' }}>LinkedIn Profile</FormLabel>
                    <Input type="url" value={formData.linkedInProfile} onChange={handleInputChange('linkedInProfile')} placeholder="linkedin.com/in/johndoe" />
                </FormControl>
                <FormControl mt={{ base: '32px', md: '0px' }}>
                    <FormLabel fontSize={{ base: 'sm', md: 'md' }}>Personal Website/Blog</FormLabel>
                    <Input type="url" value={formData.personalWebsite} onChange={handleInputChange('personalWebsite')} placeholder="johndoe.com" />
                </FormControl>
            </Flex>
        </>
    )
}

const Section = ({ label, data=[], setFormData, EditModal }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editData, setEditData] = useState({});
  
    return (
      <FormControl mr={4}>
        <FormLabel fontSize={'2xl'}>{label}</FormLabel>
        {data.length === 0 ? (
          <EmptyBox />
        ) : (
          data.map((item, index) => (
            <DataBox
                key={index}
                item={item}
                setIsEditMode={setIsEditMode}
                setEditData={setEditData}
                setIsOpen={setIsOpen}
                setFormData={setFormData}
                data={data}
                index={index}
                sectionLabel={label.toLowerCase()}  // Pass down the section label here
            />
          ))
        )}
        <Button
          size={'sm'}
          leftIcon={<AddIcon />}
          onClick={() => {
            setEditData({});
            setIsEditMode(false);
            setIsOpen(true);
          }}
          colorScheme='teal'
          variant='outline'
          mt='2'>
          {`Add ${label}`}
        </Button>
        <EditModal
          editData={editData}
          mainFormData={data}
          setMainFormData={setFormData}
          isEditMode={isEditMode}
          isOpen={isOpen}
          onClose={setIsOpen}
        />
      </FormControl>
    );
  };
  
  const EmptyBox = () => (
    <Box textAlign={'center'} borderWidth='1px' rounded='lg' p={4} mt={2} mb={2}>
      <Text>This list is empty.</Text>
    </Box>
  );
  
  const DataBox = ({ item, setIsEditMode, setEditData, setIsOpen, setFormData, data, index, sectionLabel }) => (
    <Box borderWidth='1px' rounded='lg' p={4} mt={2} mb={2}>
      <Flex justifyContent='space-between'>
        <Text color={'gray.500'} fontSize={'xl'}>
        {item[Object.keys(item)[0]]}
        </Text>
        <Flex>
          <IconButton
            onClick={() => {
              setIsEditMode(true);
              setEditData(item);
              setIsOpen(true);
            }}
            aria-label='Edit'
            icon={<EditIcon />}
            size='xs'
            mr={2}
          />
         <IconButton
            onClick={() => {
                const updatedData = data.filter((_, i) => i !== index);
                setFormData(prevFormData => ({
                ...prevFormData,
                [sectionLabel]: updatedData.length ? updatedData : [], // Use the passed down prop here
                }));
            }}
            aria-label='Delete'
            icon={<DeleteIcon />}
            size='xs'
            />
        </Flex>
      </Flex>
      <Text>{item[Object.keys(item)[1]]}</Text>
    </Box>
  );
  
  const Form2 = ({ setFormData, formData }) => {
    return (
      <>
        <Heading as='h2' size={{ base: 'md', md: 'lg' }} textAlign='center' mt='32px' mb={{ base: '0px', md: '32px' }}>
          Professional Background
        </Heading>
        <Flex flexDirection='row' mb={4}>
          <Section label='Education' data={formData.education} setFormData={setFormData} EditModal={EducationModal} />
          <Section label='Work Experience' data={formData.work} setFormData={setFormData} EditModal={WorkModal} />
        </Flex>
        <Flex mt={4} flexDirection='row'>
          <Section label='Profiles' data={formData.profiles} setFormData={setFormData} EditModal={ProfilesModal} />
          <Section label='Certifications' data={formData.certifications} setFormData={setFormData} EditModal={CertificationsModal} />
        </Flex>
      </>
    );
  };

const Form3 = ({ setFormData, formData }) => {
    return (
        <>
          <Heading as='h2' size={{ base: 'md', md: 'lg' }} textAlign='center' mt='32px' mb={{ base: '0px', md: '32px' }}>
            Achievements
          </Heading>
          <Flex flexDirection='row' mb={4}>
            <Section label='Skills' data={formData.skills} setFormData={setFormData} EditModal={SkillsModal} />
            <Section label='Languages' data={formData.languages} setFormData={setFormData} EditModal={LanguagesModal} />
          </Flex>
          <Flex mt={4} flexDirection='row'>
            <Section label='Awards' data={formData.awards} setFormData={setFormData} EditModal={AwardsModal} />
            <Section label='Publications' data={formData.publications} setFormData={setFormData} EditModal={PublicationsModal} />
          </Flex>
        </>
    );
};

const Form4 = ({ setFormData, formData, handleInputChange }) => {
    return (
        <>
          <Heading as='h2' size={{ base: 'md', md: 'lg' }} textAlign='center' mt='32px' mb={{ base: '0px', md: '32px' }}>
            Customizing
          </Heading>
          <Flex flexDirection='row' mb={4}>
            <Section label='Custom Section' data={formData.custom} setFormData={setFormData} EditModal={CustomModal} />
          </Flex>
          <Flex mt={4} flexDirection='row'>
          <FormControl mr={{ base: '0px', md: '32px' }} mt={{ base: '32px', md: '0px' }}>
            <FormLabel fontSize={{ base: 'sm', md: 'md' }}>Theme</FormLabel>
            <Select value={formData.theme || "professional"} name="theme" onChange={handleInputChange('theme')}>
                <option value="professional">Professional</option>
                <option value="modern">Modern</option>
                <option value="elegant">Elegant</option>
            </Select>
            </FormControl>
          </Flex>
        </>
    );
}
            


function Resume(){
    const toast = useToast();
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const sendData = async () => {
        setLoading(true);
        try {
            const jsonBlob = new Blob([JSON.stringify(formData)], { type: 'application/json' });
            const formDataObj = new FormData();
            formDataObj.append('file', jsonBlob, 'formData.json');          
            // fetch data from http://localhost:3100/integrations/webImport using multipart/form-data
            const response = await fetch('https://editor.easy-resume.net/integrations/webImport', {
                method: 'POST',
                body: (formDataObj),
            });
    
            const data = await response.json();
    
            if (data.slug) {
                window.location.href = `https://editor.easy-resume.net/${data.slug}/build`;
            } else {
                toast({
                    title: "Something went wrong.",
                    description: "Please try again.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
    
        } catch (error) {
            toast({
                title: "Network Error.",
                description: "Couldn't send data to the server.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
            setStep(4);
        }
    };    
    const handleNextClick = () => {
        if (step === 1 && (!formData.fullName)) {
            setErrorData(prev => ({ ...prev, fullName: !formData.fullName}));
            return;
        }

        setStep(step + 1);  // If no validation errors, proceed to next step
    };    
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phoneNumber: "",
        address: "",
        linkedInProfile: "",
        education: [],
        work: [],
        profiles: [],
        awards: [],
        certifications: [],
        publications: [],
        skills: [],
        languages: [],
        custom: [],
        theme: "",
    });

    const [errorData, setErrorData] = useState({
        fullName: false,
        email: false,
        phoneNumber: false,
        companyName: false,
        positionApplied: false
    });

    const handleInputChange = (field) => (event) => {
        if (field === 'softSkills' || field === 'technicalSkills' || field === 'languages' || field === 'certificationName') {
            const newTags = event;
            setFormData(prevState => ({
                ...prevState,
                [field]: newTags
            }));
        }
        else{
            const { value } = event.target;
            setFormData(prevState => ({
                ...prevState,
                [field]: value
            }));
            
            // Reset the error state for the specific input if its value changes
            setErrorData(prevState => ({
                ...prevState,
                [field]: value ? false : true
            }));
        }
    };    
    return(
        <>
            <Helmet>
                <title>Easy Resume - Resume Generator</title>
                <meta charset="UTF-8"/>
                <meta 
                name="description" 
                content="Create a professional resume in minutes with our free cover letter generator. AI-powered content generation supported."
                />
                <meta 
                name="keywords" 
                content="Resume, Resume Generator, Resume Builder, Resume Maker, Resume Template, Resume Example, Resume Sample, Resume Format, Resume Writing, Resume Tips, Resume Advice, Resume Help, Resume Creator, Resume Generator Free, Resume Builder Free, Resume Maker Free, Resume Template Free, Resume Example Free, Resume Sample Free, Resume Format Free, Resume Writing Free, Resume Tips Free, Resume Advice Free, Resume Help Free, Resume Creator Free, Resume Generator Online, Resume Builder Online, Resume Maker Online, Resume Template Online, Resume Example Online, Resume Sample Online, Resume Format Online, Resume Writing Online, Resume Tips Online, Resume Advice Online, Resume Help Online, Resume Creator Online, AI Resume, AI Resume Generator, AI Resume Builder, AI Resume Maker, AI Resume Template, AI Resume Example, AI Resume Sample, AI Resume Format, AI Resume Writing, AI Resume Tips, AI Resume Advice, AI Resume Help, AI Resume Creator, AI Resume Generator Free, AI Resume Builder Free, AI Resume Maker Free, AI Resume Template Free, AI Resume Example Free, AI Resume Sample Free, AI Resume Format Free, AI Resume Writing Free, AI Resume Tips Free, AI Resume Advice Free, AI Resume Help Free, AI Resume Creator Free, AI Resume Generator Online, AI Resume Builder Online, AI Resume Maker Online, AI Resume Template Online, AI Resume Example Online, AI Resume Sample Online, AI Resume Format Online, AI Resume Writing Online, AI Resume Tips Online, AI Resume Advice Online, AI Resume Help Online, AI Resume Creator Online"
                />
                <meta property="og:title" content="Easy Resume - Resume Generator" />
                <meta 
                property="og:description"
                content="Create a professional resume in minutes with our free cover letter generator. AI-powered content generation supported."
                />

                <link rel="canonical" href="https://easy-resume.net/resume" />
            </Helmet>
            {loading && (
            <Flex position="fixed" top="0" right="0" bottom="0" left="0" alignItems="center" justifyContent="center" zIndex={1000} bg="rgba(0,0,0,0.4)">
                    <Spinner size="xl" />
                </Flex>
            )}
            <NavBar/>
            <Box
            borderWidth="1px"
            rounded="lg"
           
            maxWidth={'62.5em'}
            p={8}
            m={{base: 5, md: "72px auto"}}
            as="form">
                    {/* show forms according to the state */}
                    {step === 1 ? <><StepperBox activeStep={0} /> <Form1 isError={errorData} handleInputChange={handleInputChange} formData={formData}/></> : null}
                    {step === 2 ? <><StepperBox activeStep={1} /> <Form2 setFormData={setFormData} formData={formData}/></> : null}
                    {step === 3 ? <><StepperBox activeStep={2} /> <Form3 setFormData={setFormData} formData={formData}/></> : null}
                    {step === 4 ? <><StepperBox activeStep={3} /> <Form4 handleInputChange={handleInputChange} setFormData={setFormData} formData={formData}/></> : null}
                    {step === 5 ? <><StepperBox activeStep={4} /> <Form4 handleInputChange={handleInputChange} setFormData={setFormData} formData={formData}/></> : null}
                    {/* back and next button */}
                    <Flex w="100%" justifyContent="space-between">
            <Flex>
                <Button
                    mt={{base: '16px', md: '32px'}}
                    size={{base: 'sm', md: 'md'}}
                    onClick={() => {
                        setStep(step - 1)
                    }}
                    isDisabled={step===1}
                    colorScheme="teal"
                    variant="solid"
                    w={{base: '6rem', md: '7rem'}}
                    mr="5%">
                    Back
                </Button>
                {step >= 4 ? (
                <Button
                    mt={{base: '16px', md: '32px'}}
                    size={{base: 'sm', md: 'md'}}
                    w={{base: '6rem', md: '7rem'}}
                    display={{base: 'none', md: 'block'}}
                    isDisabled={true}
                    colorScheme="teal"
                    variant="outline">
                    Next
                </Button>
                ) : 
                <Button
                    mt={{base: '16px', md: '32px'}}
                    size={{base: 'sm', md: 'md'}}
                    w={{base: '6rem', md: '7rem'}}
                    isDisabled={false}
                    onClick={handleNextClick}
                    colorScheme="teal"
                    variant="outline">
                    Next
                </Button>
                }
            </Flex>
                        {step >= 4 ? (
                        <Button
                            mt={{base: '16px', md: '32px'}}
                            size={{base: 'sm', md: 'md'}}
                            w={{base: '6rem', md: '7rem'}}
                            colorScheme="green"
                            variant="solid"
                            onClick={() => {
                                setStep(5);
                                sendData();
                            }}>
                            Create
                        </Button>
                        ) : null}
                    </Flex>
            </Box>
            <Footer/>
        </>
    )
}

export default Resume;