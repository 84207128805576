import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Box, Text, Stack, Button } from '@chakra-ui/react';

import { useToast } from "@chakra-ui/react"
import { useCoverLetter } from './CoverLetterContext';

const RightSideBar = ({userParameters, setTextArea, setLoading}) => {

    const templates = useMemo(() => [
        { id: 1, name: 'Professional', content: '' },
        { id: 2, name: 'Modern', content: '' },
        { id: 3, name: 'Elegant', content: ''},
        { id: 4, name: 'Classic', content: ''},
        { id: 5, name: 'Minimalist', content: '' },
        { id: 6, name: 'Bold', content: '' },
        { id: 7, name: 'Narrative', content: '' },
        { id: 8, name: 'Precision', content: '' },
        { id: 9, name: 'ProActive', content: '' },
        { id: 10, name: 'Refined', content: '' },
        { id: 11, name: 'Streamlined', content: '' },
    ], []);

    const [visibleIndexes, setVisibleIndexes] = useState(new Set());
    const itemsRef = useRef([]);

    useEffect(() => {
        const updateVisibility = (index, isIntersecting) => {
            setVisibleIndexes(prevSet => {
                const newSet = new Set(prevSet);
                if (isIntersecting) {
                    newSet.add(index);
                } else {
                    newSet.delete(index);
                }
                return newSet;
            });
        };

        const observers = templates.map((_, index) => {
            const observer = new IntersectionObserver((entries) => {
                updateVisibility(index, entries[0].isIntersecting);
            }, {
                threshold: 0.1
            });
            observer.observe(itemsRef.current[index]);
            return observer;
        });

        return () => observers.forEach(o => o.disconnect());
    }, [templates]);

    const lastVisibleIndex = [...visibleIndexes].reduce((a, b) => Math.max(a, b), -1);

    const toast = useToast();
    
    const handleTextCreation = async (templateName) => {
        userParameters.designTemplate = templateName;
        setLoading(true);
        try {
            const response = await fetch('https://editor.easy-resume.net/cover-letter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({api: "no-api", formData: userParameters}),
            });
    
            const data = await response.json();
    
            if (data.coverLetter) {
                setTextArea(data.coverLetter);
            } else {
                toast({
                    title: "Something went wrong.",
                    description: "Please try again later.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
    
        } catch (error) {
            toast({
                title: "Network Error.",
                description: "Please check your internet connection.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    }    

    return (
        <Box maxH={'300px'} pt="0.5rem" pb="2rem" pl="2rem" pr="2rem" overflowY='auto'>
            <Text fontSize={{base: "lg", md:"xl"}} mb="1.2rem" textAlign="center">Templates</Text>
            <Stack overflowX={{base: "auto", md: 'visible'}} direction={{base:'row', md:'column'}} spacing={4} align="center">
                {templates.map((template, index) => (
                    <Button
                        fontSize={{base: "sm", md:"md"}}
                        minWidth={{base: '70%', md: '120px'}}
                        onClick={() => handleTextCreation(template.name)
                        }
                        w={'120px'}
                        key={template.id}
                        variant="outline"
                        ref={el => itemsRef.current[index] = el}
                        opacity={index === lastVisibleIndex ? 0.4 : 1}
                    >
                        {template.name}
                    </Button>
                ))}
            </Stack>
        </Box>
    );
};

export default RightSideBar;