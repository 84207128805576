import React, { useState, useEffect } from 'react';
import { Button, ModalFooter, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Textarea, Flex, Text } from '@chakra-ui/react';
import { AddIcon, EditIcon } from '@chakra-ui/icons';

export default function CertificationsModal({ editData, setMainFormData, isOpen, onClose, isEditMode }) {
  const initialFormData = {
    name: '',
    issuer: '',
    date: '',
    website: '',
    summary: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isInvalid, setIsInvalid] = useState(true);

  useEffect(() => {
    const newFormData = isEditMode && editData ? editData : initialFormData;
    setFormData(newFormData);
    setIsInvalid(isEditMode ? false : !newFormData.name || !newFormData.issuer);
  }, [isEditMode, editData]);

  const handleChange = e => {
    const newFormData = { ...formData, [e.target.name]: e.target.value };
    setFormData(newFormData);
    setIsInvalid(!newFormData.name || !newFormData.issuer);
  };

  const handleSubmit = () => {
    if (!isInvalid) {
      setMainFormData(prev => ({ ...prev, certifications: isEditMode ? prev.certifications.map(c => c === editData ? formData : c) : [...prev.certifications, formData] }));
      onClose();
    }
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex alignItems="center">
            {isEditMode ? <EditIcon boxSize={4} /> : <AddIcon boxSize={4} />}
            <Text ml="2">{isEditMode ? 'Edit Certificate' : 'Add Certificate'}</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"row"} mt={4}>
            {renderInput('Name', 'name', true)}
            {renderInput('Issuer', 'issuer', true, 2)}
          </Flex>
          <Flex flexDirection={"row"} mt={4}>
            {renderInput('Date', 'date', false, 0, 'date')}
            {renderInput('Website', 'website', false, 2)}
          </Flex>
          <Flex mt={4}>
          {renderInput('Summary', 'summary', false, 'textarea')}
        </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit} isDisabled={isInvalid}> {isEditMode ? 'Edit' : 'Add'}</Button>
          <Button variant="ghost" onClick={()=>onClose()}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  function renderInput(label, name, isRequired = false, ml = 0, type = 'text') {
    return (
      <FormControl isRequired={isRequired} ml={ml} isInvalid={isInvalid && isRequired && !formData[name]}>
        <FormLabel>{label}</FormLabel>
        {(type === 'textarea') ? <Textarea name={name} value={formData[name] || ''} onChange={handleChange} /> : <Input type={type} name={name} value={formData[name] || ''} onChange={handleChange} />}
      </FormControl>
    );
  }
};