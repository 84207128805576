import { 
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalFooter, 
    ModalBody, 
    ModalCloseButton, 
    Button, 
    Input, 
    Text, 
    Box,
    InputGroup,
    InputRightElement,
    IconButton,
    Switch,
    Flex,
} from "@chakra-ui/react";
import { useState } from "react";
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useHelp } from "./HelpContext";
import { useApi } from "./ApiContext";

export default function OpenAIApiModal({ isOpen, onClose, handleProceed, onlyModal }) {
    // get the apiKey from local storage
    const { apiKey, setApiKey } = useApi();
    const [isInvalid, setIsInvalid] = useState(false);
    const [ helpManage, setHelpManage ] = useState(false);
    const { showHelp, setShowHelp } = useHelp();

    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    if (isOpen && !showHelp && !helpManage && !onlyModal) {
        handleProceed("no-api");
    }

    if(onlyModal){
        return (
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Do you have an OpenAI API key?</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mb="4">We utilize OpenAI's services to generate resumes and cover letters using AI. As this service is paid and our platform operates on ad revenues, we can't afford the costs. Therefore, users with an OpenAI API key can use this AI feature.</Text>
                    
                    <Box mt="4" position="relative" overflow="hidden" paddingTop="56.25%">
                        <iframe 
                            title="Why do we need OpenAI API key?" 
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%"
                            }}
                            src="https://www.youtube.com/embed/nafDyRsVnXU"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                    </Box>
                    
                    <Box mb="4" mt="4">
                        <Text mb="2">Steps to get OpenAI API key:</Text>
                        <ol style={{ marginLeft: '14px', paddingLeft: '0' }}>
                            <li>Create an OpenAI account‍</li>
                            <li>Verify your account‍</li>
                            <li>Log into your account‍</li>
                            <li>Navigate to the API section.</li>
                            <li>Generate a new API key.</li>
                            <li>Save your API key.</li>
                            <li>Set up billing.</li>
                            <li>Set usage limits.</li>
                            <li>Start using your API key.</li>
                        </ol>
                    </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        )
    }
    else{
        return (
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Do you have an OpenAI API key?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text mb="4">We utilize OpenAI's services to generate resumes and cover letters using AI. As this service is paid and our platform operates on ad revenues, we can't afford the costs. Therefore, users with an OpenAI API key can use this AI feature.</Text>
                        
                        <Box mt="4" position="relative" overflow="hidden" paddingTop="56.25%">
                            <iframe 
                                title="Why do we need OpenAI API key?" 
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%"
                                }}
                                src="https://www.youtube.com/embed/nafDyRsVnXU"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            ></iframe>
                        </Box>
                        
                        <Box mb="4" mt="4">
                            <Text mb="2">Steps to get OpenAI API key:</Text>
                            <ol style={{ marginLeft: '14px', paddingLeft: '0' }}>
                                <li>Create an OpenAI account‍</li>
                                <li>Verify your account‍</li>
                                <li>Log into your account‍</li>
                                <li>Navigate to the API section.</li>
                                <li>Generate a new API key.</li>
                                <li>Save your API key.</li>
                                <li>Set up billing.</li>
                                <li>Set usage limits.</li>
                                <li>Start using your API key.</li>
                            </ol>
                        </Box>
                        
                        <Box mt="4">
                        <InputGroup>
                        <Input
                            autoComplete="off"
                            type={show ? 'text' : 'password'}
                            placeholder='OpenAI API Key'
                            isInvalid={isInvalid} 
                            value={apiKey}
                            onChange={(e) => {
                                setIsInvalid(false);  // Reset to valid when user starts typing
                            }}
                        />
                        <InputRightElement>
                                <IconButton 
                                size='sm' 
                                icon={show ? <ViewOffIcon /> : <ViewIcon />}
                                aria-label={show ? 'Hide api' : 'Show api'} 
                                onClick={handleClick}
                                />
                        </InputRightElement>
                        </InputGroup>
                            <Text mt="2" fontSize="sm" color="gray.500">
                                Note: We save your API key to local storage. So, if you don't change the browser, you can use the same key again.
                            </Text>
                        </Box>
                        {/* A switch button with a text that says: "Do not show this help page again." */}
                        <Flex mt="2" flexDirection={'row'}>
                            <Text mr="2" fontSize="sm" color="gray.500">Do not show this help page again: </Text>
                            <Switch 
                                id="switch" 
                                colorScheme="green" 
                                size="md" 
                                isChecked={showHelp}
                                onChange={(e) => {
                                    const newValue = e.target.checked;
                                    setHelpManage(true);
                                    setShowHelp(newValue);
                                }}
                            />

                        </Flex>

                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={()=>handleProceed("no-api")}>Continue without AI</Button>
                        <Button colorScheme="green" onClick={()=>handleProceed(apiKey)} >Proceed with AI</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        )
    }
}