import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

export const CoverLetterContext = createContext();

export const CoverLetterProvider = ({ children }) => {
  const initialCoverLetter = localStorage.getItem("coverLetter");
  const [coverLetter, setCoverLetterBase] = useState(
    JSON.parse(initialCoverLetter)?.trim() ?? ""
  );

  const setCoverLetter = useCallback(
    (newCoverLetter) => {
      setCoverLetterBase(newCoverLetter.trim());
    },
    [setCoverLetterBase]
  );

  useEffect(() => {
    localStorage.setItem("coverLetter", JSON.stringify(coverLetter));
  }, [coverLetter]);

  const value = {
    coverLetter,
    setCoverLetter,
  };

  return <CoverLetterContext.Provider value={value}>{children}</CoverLetterContext.Provider>;
};

export const useCoverLetter = () => {
  const context = useContext(CoverLetterContext);
  if (!context) {
    throw new Error('coverLetter must be used within a CoverLetterProvider');
  }
  return context;
};