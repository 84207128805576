import React, { useState, useEffect } from 'react';
import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Box, Flex, Text } from '@chakra-ui/react';
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import ArrayInput from './ArrayInput';

export default function CustomModal({ editData, setMainFormData, isOpen, onClose, isEditMode }) {
  const initialFormData = {
    title: '',
    subtitle: '',
    startDate: '',
    endDate: '',
    website: '',
    levelText: '',
    levelBar: 0,
    summary: '',
    keywords: []
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isInvalid, setIsInvalid] = useState(true);

  useEffect(() => {
    const newFormData = isEditMode && editData ? editData : initialFormData;
    setFormData(newFormData);
    setIsInvalid(isEditMode ? false : !newFormData.title);
  }, [isEditMode, editData]);

  const handleChange = (e, name) => {
    const value = name ? e : e.target.value;
    const newName = name || e.target.name;
    const newFormData = { ...formData, [newName]: value };
    setFormData(newFormData);
    setIsInvalid(!newFormData.title);
  };

  const handleSubmit = () => {
    if (!isInvalid) {
      setMainFormData(prev => ({ ...prev, custom: isEditMode ? prev.custom.map(c => c === editData ? formData : c) : [...prev.custom, formData] }));
      onClose();
    }
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex alignItems="center">
            {isEditMode ? <EditIcon boxSize={4} /> : <AddIcon boxSize={4} />}
            <Text ml="2">{isEditMode ? 'Edit Custom' : 'Add Custom'}</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"row"} mt={4}>
            {renderInput('Title', 'title', true)}
            {renderInput('Subtitle', 'subtitle', false, 2)}
          </Flex>
          <Flex flexDirection={"row"} mt={4}>
            {renderInput('Start Date', 'startDate', false, 0, 'date')}
            {renderInput('End Date', 'endDate', false, 2, 'date')}
          </Flex>
          <Flex mt={4}>
          {renderInput('Website', 'website', false)}
            </Flex>
            <Flex mt={4}>
          {renderInput('Level (Text)', 'levelText', false)}
            </Flex>
          <FormControl mt={4}>
            <FormLabel>Level (Bar)</FormLabel>
            <Slider value={formData.levelBar} onChange={(e) => handleChange(e, 'levelBar')} min={0} max={100} step={10}>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={6}>
                <Box color="tomato" fontWeight="bold">
                  {formData.levelBar}
                </Box>
              </SliderThumb>
            </Slider>
          </FormControl>
          <Flex mt={4}>
          {renderInput('Summary', 'summary', false, 'text')}
            </Flex>
          <FormControl mt={4}>
            <FormLabel>Keywords</FormLabel>
            <ArrayInput label="Keywords" value={formData.keywords} onChange={(value) => handleChange(value, 'keywords')} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit} isDisabled={isInvalid}>{isEditMode ? 'Edit' : 'Add'}</Button>
          <Button variant="ghost" onClick={()=>onClose()}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  function renderInput(label, name, isRequired = false, ml = 0, type = 'text') {
    return (
      <FormControl isRequired={isRequired} ml={ml} isInvalid={isInvalid && isRequired && !formData[name]}>
        <FormLabel>{label}</FormLabel>
        <Input type={type} name={name} value={formData[name] || ''} onChange={handleChange} />
      </FormControl>
    );
  }
};