import { React } from 'react'
import {
  Button,
  Stack,
  Text,
  Flex
} from '@chakra-ui/react'
import { useColorMode } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ResumeSvg } from './assets/resume.svg'
import { ReactComponent as AiSvg } from './assets/ai.svg'
import { ReactComponent as MoneySvg } from './assets/money.svg'
import { ReactComponent as ContactSvg } from './assets/contact.svg'
import { ReactComponent as ImportSvg } from './assets/import.svg'
import { ReactComponent as LetterSvg } from './assets/coverLetter.svg'
import { Helmet } from 'react-helmet';

import { extendTheme } from "@chakra-ui/react";

import NavBar from './components/NavBar'
import Footer from './components/Footer'

function App() {
  const navigate = useNavigate()
  return (
    <>
      <Helmet>
        <title>Easy Resume - Quick and Intelligent Resume Builder</title>
        <meta charset="UTF-8"/>
        <meta 
          name="description" 
          content="Easy Resume offers a seamless experience in creating professional resumes and cover letters with the help of AI. Craft or improve your resume in minutes!" 
        />
        <meta 
          name="keywords" 
          content="Resume, Cover Letter, AI, Resume Builder, Easy Resume, Quick Resume, Intelligent Resume Builder, Professional Resume, Import Resume, LinkedIn Import"
        />
        <meta property="og:title" content="Easy Resume - Quick and Intelligent Resume Builder" />
        <meta 
          property="og:description" 
          content="Craft professional resumes and cover letters effortlessly with Easy Resume! Experience a hassle-free, AI-driven approach to building resumes in minutes." 
        />
        <meta property="og:image" content="https://easy-resume.net/logo.png" />
        <meta property="og:url" content="https://easy-resume.net" />

        <meta name="author" content="Easy Resume" />
        <meta name="contact" content="info@easy-resume.net" />
        <meta name="rating" content="general" />
        <meta name="generator" content="Easy Resume" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Easy Resume" />

        <link rel="canonical" href="https://easy-resume.net" />
      </Helmet>
      <NavBar />
      {/* A warm welcome message that explains the website using ResumeSvg component on the right and text on the left */}
      <Stack mb={'50px'} alignItems={'center'}>
        <Stack maxWidth={'1280px' }
          direction={{base: 'column', md: 'row'}}
          p={'16px'}
          align={'center'}
        >
          <Stack
            direction={'column'}
            align={'center'}
            textAlign={'center'}
            spacing={'16px'}
          >
            <Flex mt={'50px'} display={{base: 'flex', md: 'none'}} borderRadius={'3xl'} border={'2px'} height={'100px'} width={'100px'}>
              <ResumeSvg />
            </Flex>
            <Text fontSize={{ base: 'xl', md: '3xl' }} fontWeight={'bold'}>
              Welcome to Easy Resume!
            </Text>
            <Text fontSize={{ base: 'lg', md: 'xl' }}>
              Do you want to create a resume in minutes? You're in the right
              place. With our AI, creating a resume and cover letter is as easy
              as pie. No fuss, no hassle.
            </Text>
            <Text fontSize={{ base: 'lg', md: 'xl' }}>
              Ready to start? Just click the button below.
            </Text>
            <Button
              size={{ base: 'sm', md: 'md' }}
              mt={'10px'}
              onClick={() => navigate('/resume')}
              colorScheme={'blue'}
            >
              Create Resume
            </Button>
          </Stack>
          <Flex display={{base: 'none', md: 'flex'}} height='600px' width='600px' >
            <ResumeSvg />
          </Flex>
        </Stack>
        <Stack maxWidth={'1280px'}
          direction={{ base: 'column', md: 'row' }}
          p={'16px'}
          align={'center'}
        >
          <Flex mt={'50px'} display={{base: 'flex', md: 'none'}} borderRadius={'3xl'} border={'2px'} height={'100px'} width={'100px'}>
            <LetterSvg />
          </Flex>
          <Flex display={{base: 'none', md: 'flex'}} height='600px' width='600px' >
            <LetterSvg />
          </Flex>
          <Stack
            direction={'column'}
            align={'center'}
            textAlign={'center'}
            spacing={'16px'}
          >

            <Text fontSize={{ base: 'xl', md: '3xl' }} fontWeight={'bold'}>
              Need a Cover Letter?
            </Text>
            <Text fontSize={{ base: 'lg', md: 'xl' }}>
              We understand that a resume is not complete without a cover letter. With Easy Resume, creating a professional cover letter is just a click away!
            </Text>
            <Text fontSize={{ base: 'lg', md: 'xl' }}>
              Ready to craft a cover letter that stands out? Just click below!
            </Text>
            <Button
              size={{ base: 'sm', md: 'md' }}
              mt={'10px'}
              onClick={() => navigate('/cover-letter')}
              colorScheme={'blue'}
            >
              Create Cover Letter
            </Button>
          </Stack>
        </Stack>
        <Stack maxWidth={'1280px'}
          direction={{ base: 'column', md: 'row' }}
          p={'16px'}
          align={'center'}
        >
          <Stack
            direction={'column'}
            align={'center'}
            textAlign={'center'}
            spacing={'16px'}
          >
            <Flex mt={'50px'} display={{base: 'flex', md: 'none'}} borderRadius={'3xl'} border={'2px'} height={'100px'} width={'100px'}>
              <ImportSvg />
            </Flex>
            <Text fontSize={{ base: 'xl', md: '3xl' }} fontWeight={'bold'}>
              Import Your Resume
            </Text>
            <Text fontSize={{ base: 'lg', md: 'xl' }}>
              Do you already have a resume created with our website? No worries! Import your existing documents and our AI will help you enhance them. Also we support importing from LinkedIn.
            </Text>
            <Text fontSize={{ base: 'lg', md: 'xl' }}>
              Click below to import and get started on improving your resume instantly!
            </Text>
            <Button
              size={{ base: 'sm', md: 'md' }}
              mt={'10px'}
              onClick={() => navigate('/import')}
              colorScheme={'blue'}
            >
              Import Now
            </Button>
          </Stack>
          <Flex display={{base: 'none', md: 'flex'}} height='600px' width='600px' >
            <ImportSvg />
          </Flex>
        </Stack>

        {/* Continue explaning website using AiSvg component on the right and text on the left */}
        <Stack maxWidth={'1280px'}
          direction={{ base: 'column', md: 'row' }}
          p={'16px'}
          align={'center'}
        >
          <Flex mt={'50px'} display={{base: 'flex', md: 'none'}} borderRadius={'3xl'} border={'2px'} height={'100px'} width={'100px'}>
            <AiSvg />
          </Flex>
          <Flex display={{base: 'none', md: 'flex'}} height='600px' width='600px' >
            <AiSvg />
          </Flex>
          <Stack
            direction={'column'}
            align={'center'}
            textAlign={'center'}
            spacing={'16px'}
          >
            <Text fontSize={{ base: 'xl', md: '3xl' }} fontWeight={'bold'}>
              How does it work?
            </Text>
            <Text fontSize={{ base: 'lg', md: 'xl' }}>
              Ever wonder how? It's all thanks to our smart AI! Just give it a
              bit of info by filling out a form, and it crafts your resume and
              cover letter for you. No long waits, no complex steps.
            </Text>
            <Text fontSize={{ base: 'lg', md: 'xl' }}>
              We're here to simplify things and make them awesome for you.
            </Text>
            <Text fontSize={{ base: 'lg', md: 'xl' }}>
              Curious about the details? Tap 'Learn more' right above.
            </Text>
            <Button
              size={{ base: 'sm', md: 'md' }}
              mt={'10px'}
              onClick={() => navigate('/faq')}
              colorScheme={'blue'}
            >
              Learn More
            </Button>
          </Stack>
        </Stack>
        {/* Continue explaning website's money earning is by ads, not selling their informations to third parties so there is nothing to afraid. Use MoneySvg component on the left and text on the left */}
        <Stack maxWidth={'1280px'}
          direction={{ base: 'column', md: 'row' }}
          p={'16px'}
          align={'center'}
        >
          <Stack
            direction={'column'}
            align={'center'}
            textAlign={'center'}
            spacing={'16px'}
          >
            <Flex mt={'50px'} display={{base: 'flex', md: 'none'}} borderRadius={'3xl'} border={'2px'} height={'100px'} width={'100px'}>
              <MoneySvg />
            </Flex>
            <Text fontSize={{ base: 'xl', md: '3xl' }} fontWeight={'bold'}>
              How do we earn money?
            </Text>
            <Text fontSize={{ base: 'lg', md: 'xl' }}>
              At Easy Resume, we rely on advertisements to keep our AI-driven
              services free for you. But that doesn't mean compromising on your
              security.
            </Text>
            <Text fontSize={{ base: 'lg', md: 'xl' }}>
              Your personal data, resumes, and cover letters remain strictly
              confidential. We promise never to sell or share your information
              with third parties. Browse with confidence, and trust that you're
              in safe hands.
            </Text>
            <Button
              size={{ base: 'sm', md: 'md' }}
              mt={'10px'}
              onClick={() => navigate('/privacy-policy')}
              colorScheme={'blue'}
            >
              Privacy Policy
            </Button>
          </Stack>
          <Flex display={{base: 'none', md: 'flex'}} height='600px' width='600px' >
            <MoneySvg />
          </Flex>
        </Stack>
        <Stack maxWidth={'1280px'}
          direction={{ base: 'column', md: 'row' }}
          p={'16px'}
          align={'center'}
        >
          <Flex mt={'50px'} display={{base: 'flex', md: 'none'}} borderRadius={'3xl'} border={'2px'} height={'100px'} width={'100px'}>
            <ContactSvg />
          </Flex>
          <Flex display={{base: 'none', md: 'flex'}} height='600px' width='600px' >
            <ContactSvg />
          </Flex>
          <Stack
            direction={'column'}
            align={'center'}
            textAlign={'center'}
            spacing={'16px'}
          >
            <Text fontSize={{ base: 'xl', md: '3xl' }} fontWeight={'bold'}>
              Do you have more questions?
            </Text>
            <Text fontSize={{ base: 'lg', md: 'xl' }}>
              If something's on your mind, don't hesitate to reach out. We
              genuinely love hearing from you and helping out.
            </Text>
            <Text fontSize={{ base: 'lg', md: 'xl' }}>
              Use the button below to send us an email. We're here to guide you.
            </Text>
            <Button
              size={{ base: 'sm', md: 'md' }}
              mt={'10px'}
              onClick={() => navigate('/contact-us')}
              colorScheme={'blue'}
            >
              Get In Touch
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Footer />
    </>
  )
}

export default App