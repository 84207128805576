import React, { useState, useRef } from "react";
import {
    theme,
    Box,
    Flex,
    Heading,
    Button,
    useToast,
    Textarea,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Input,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    InputGroup,
    InputRightElement,
    IconButton,
    Spinner,
    Icon,
    Text
} from "@chakra-ui/react";
import { ViewOffIcon, ViewIcon, QuestionIcon } from "@chakra-ui/icons";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import RightSideBar from "./components/RightSideBar"; // Assuming you have this component for Templates
import EditDetailsModal from "./components/EditDetailsModal";
import OpenAIApiModal from "./components/OpenAIApiModal";
import { useLocation } from "react-router-dom";
import { CopyIcon } from '@chakra-ui/icons';

import { useApi } from "./components/ApiContext";
import { useCoverLetter } from "./components/CoverLetterContext";


function CoverLetterPage() {
    const [isCopied, setIsCopied] = useState(false);
    const handleCopy = () => {
        navigator.clipboard.writeText(coverLetter); // Bu kısmı Textarea içeriğini kopyalamak için güncelleyin
        setIsCopied(true);
        toast({
            title: "Text Copied",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right"
        });

        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    }

    const location = useLocation();
    const [formData, setFormData] = useState(location.state?.formData);

    const { apiKey, setApiKey } = useApi();

    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);

    const handleTxtDownload = () => {
        const element = document.createElement("a");
        const file = new Blob([coverLetter], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "cover-letter.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    const handlePdfDownload = () => {
        // send the cover letter txt to http://localhost:3100/pdf-download
        try {
            fetch("https://editor.easy-resume.net/pdf-download", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ coverLetter }), // Whatever data you need to send to the API
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob();
                })
                .then(data => {
                    const element = document.createElement("a");
                    const file = new Blob([data], { type: 'application/pdf' });
                    element.href = URL.createObjectURL(file);
                    element.download = "cover-letter.pdf";
                    document.body.appendChild(element); // Required for this to work in FireFox
                    element.click();
                })
                .catch(error => {
                    console.error('There was a problem fetching the pdf:', error);
                });
        }
        catch (error) {
            toast({
                title: "Network Error.",
                description: "Please check your internet connection.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }

    const { coverLetter, setCoverLetter } = useCoverLetter();
    const [isOpen, setIsOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const toast = useToast();
    const onClose = () => setIsOpen(false);
    const onCloseEdit = () => setIsEditOpen(false);
    const cancelRef = useRef();
    const [loading, setLoading] = useState(false);
    const [isHelpOpen, setHelpOpen] = useState(false);

    // send the api ket to the server and check if it is valid
    const handleCheck = async (api) => {
        setLoading(true);
        try {
            const response = await fetch("https://editor.easy-resume.net/check-api", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ api }), // Whatever data you need to send to the API
            });
            const responseJson = await response.json();
            const isValid = responseJson['valid'];
            if (isValid) {
                setApiKey(api);
                setIsOpen(true);
            }
            else {
                const message = responseJson['message'];
                const description = responseJson['description'];
                toast({
                    title: message,
                    description: description,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
        catch (error) {
            toast({
                title: "Network Error.",
                description: "Please check your internet connection.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
        finally {
            setLoading(false);
        }
    };
    const handleOpenAiClose = () => {
        setHelpOpen(false);
    }
    const handleRecreate = async () => {
        setIsOpen(false);
        setLoading(true);
        const api = apiKey;
        try {
            // get api from local storage
            const response = await fetch("https://editor.easy-resume.net/cover-letter", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ formData, api }), // Whatever data you need to send to the API
            });

            const data = await response.json();

            if (data.coverLetter) {
                // save apiKey to local storage
                setApiKey(api);
                setCoverLetter(data.coverLetter);
            } else {
                toast({
                    title: "API Error.",
                    description: "Invalid API Key or insufficient credits.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
        catch (error) {
            toast({
                title: "Network Error.",
                description: "Please check your internet connection.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
        finally {
            setLoading(false);
        }
    };

    const saveData = (data) => {
        // check if required fields are empty
        if (!data.fullName || !data.email || !data.companyName || !data.positionApplied) {
            toast({
                title: "Required Fields Missing.",
                description: "Please fill all the required fields.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        setFormData(data);
        onCloseEdit();
    }

    return (
        <>
            <OpenAIApiModal isOpen={isHelpOpen} onClose={handleOpenAiClose} onlyModal={true} />
            {loading && (
                <Flex
                    position="fixed"
                    top="0"
                    right="0"
                    bottom="0"
                    left="0"
                    alignItems="center"
                    justifyContent="center"
                    zIndex={2000}
                    bg="rgba(0,0,0,0.4)"
                >
                    <Flex flexDirection="column" alignItems="center">
                        <Spinner size="xl" />
                        <Text mt={8} color="white">Please be patient. This process may take 10 to 15 seconds.</Text> {/* Adjust the margin and color as necessary */}
                    </Flex>
                </Flex>
            )}
            <NavBar />
            <Flex mt={8} direction="column" alignItems="center" justifyContent="center" minH="90vh">
                <Heading as="h2" size={{base: 'md', md:'lg'}} textAlign="center">
                    Cover Letter Editor
                </Heading>
                <Box
                    borderWidth="1px"
                    rounded="lg"
                    shadow="1px 1px 3px rgba(0,0,0,0.3)"
                    maxWidth={'1000px'}
                    p={8}
                    w="80%"
                    m="2rem auto"
                >
                    <Flex
                        // h="50px"
                        // mb="1rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        py="0.5rem"
                        flexDirection={{ base: 'column', md: 'row' }}
                    >
                        <InputGroup>
                            <Input
                                autoComplete="off"
                                type={show ? 'text' : 'password'}
                                placeholder='OpenAI API Key'
                                value={apiKey}
                                onChange={(e) => setApiKey(e.target.value)}
                            />
                            <InputRightElement width="4.5rem">
                                <Box mr={2} as="span" display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                    <IconButton
                                        size='sm'
                                        mr={1}
                                        icon={show ? <ViewOffIcon /> : <ViewIcon />}
                                        aria-label={show ? 'Hide api' : 'Show api'}
                                        onClick={handleClick}
                                    />
                                    <IconButton
                                        size='sm'
                                        icon={<QuestionIcon />}
                                        aria-label={'Get help'}
                                        onClick={() => setHelpOpen(true)}
                                    />
                                </Box>
                            </InputRightElement>
                        </InputGroup>
                        <Button
                            fontSize={{ base: 'sm', md: 'md' }}
                            my={{ base: '1rem', md: '0' }}
                            w={{ base: '100%', md: '120px' }}
                            mr={'24px'}
                            ml={"32px"}
                            onClick={() => {
                                handleCheck(apiKey);
                            }}
                        >
                            Use AI
                        </Button>

                    </Flex>
                    <Flex flexDirection={{ base: 'column', md: 'row' }}>
                        <Box position="relative" width={'100%'}>
                            <Textarea
                                fontSize={{ base: 'sm', md: 'md' }}
                                id='textArea'
                                value={coverLetter}
                                onChange={(e) => setCoverLetter(e.target.value)}
                                placeholder="Cover Letter content goes here..."
                                size="lg"
                                resize="vertical"
                                h="300px"
                                mb="1rem"
                            />
                            <Icon
                                zIndex={1000}
                                as={CopyIcon}
                                position="absolute"
                                top="3"
                                right="3"
                                boxSize="6"
                                color="gray.600"
                                cursor="pointer"
                                onClick={handleCopy}
                            />
                        </Box>
                        <RightSideBar setLoading={setLoading} userParameters={formData} setTextArea={setCoverLetter} />
                    </Flex>
                    {/* edit details button */}
                    <EditDetailsModal saveData={saveData} onClose={onCloseEdit} initialData={formData} isOpen={isEditOpen} />
                    <Flex>
                    <Button fontSize={{base: 'sm', md: 'md'}} onClick={() => setIsEditOpen(true)} mr={4}>
                        Edit Details
                    </Button>
                    <Menu>
                        <MenuButton fontSize={{base: 'sm', md: 'md'}} as={Button} rightIcon={<i className="fas fa-download"></i>}>
                            Download
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={handleTxtDownload}>Download as .txt</MenuItem>
                            <MenuItem onClick={handlePdfDownload}>Download as .pdf</MenuItem>
                        </MenuList>
                    </Menu>
                    </Flex>
                </Box>
            </Flex>
            {/* Alert Dialog when Recreate button is clicked */}
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Recreate Cover Letter
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure? This will replace your current cover letter.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={handleRecreate} ml={3}>
                                Recreate
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <Footer />
        </>
    );
}

export default CoverLetterPage;