import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Flex
} from "@chakra-ui/react";
import { Form1, Form2, Form3, Form4 } from '../CoverLetter'; // Assuming you separated each form and the stepper box to individual components.

const EditDetailsModal = ({ isOpen, onClose, initialData, saveData }) => {
    const [errorData, setErrorData] = useState({
        fullName: false,
        email: false,
        phoneNumber: false,
        companyName: false,
        positionApplied: false
    });
    const handleNextClick = () => {
        if (step === 1 && (!formData.fullName || !formData.email)) {
            setErrorData(prev => ({ ...prev, fullName: !formData.fullName, email: !formData.email }));
            return;
        }
        if (step === 2 && !formData.companyName) {
            setErrorData(prev => ({ ...prev, companyName: !formData.companyName }));
            return;
        }
        // Add checks for other steps if needed
    
        setStep(step + 1);  // If no validation errors, proceed to next step
    };    
    // Replicate the state and logic from the CoverLetter component.
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState(initialData);

    const handleInputChange = (field) => (event) => {
        if (field === 'references' || field === 'experiences' || field === 'skillsAndAbilities') {
            const newTags = event;
            setFormData(prevState => ({
                ...prevState,
                [field]: newTags
            }));
        }
        else{
            const { value } = event.target;
            setFormData(prevState => ({
                ...prevState,
                [field]: value
            }));
            
            // Reset the error state for the specific input if its value changes
            setErrorData(prevState => ({
                ...prevState,
                [field]: value ? false : true
            }));
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="3xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Your Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {step === 1 && <><Form1 marginTop={0} isError={errorData} handleInputChange={handleInputChange} formData={formData} /></>}
                        {step === 2 && <><Form2 marginTop={0} isError={errorData}  handleInputChange={handleInputChange} formData={formData} /></>}
                        {step === 3 && <><Form3 marginTop={0} handleInputChange={handleInputChange} formData={formData} /></>}
                        {step === 4 && <><Form4 marginTop={0} handleInputChange={handleInputChange} formData={formData} /></>}
                        {/* Add navigation controls and other logic like saving or updating the data as needed */}
                    </ModalBody>
                    <ModalFooter>
                        <Flex w="100%" justifyContent="space-between">
                        <Flex>
                    <Button
                        onClick={() => {
                            setStep(step - 1)
                        }}
                        isDisabled={step === 1}
                        colorScheme="teal"
                        variant="solid"
                        w="7rem"
                        mr="5%">
                        Back
                    </Button>
                    <Button
                        w="7rem"
                        isDisabled={step >= 4}
                        onClick={handleNextClick}
                        colorScheme="teal"
                        variant="outline">
                        Next
                    </Button>
                    </Flex>
                        <Button
                        onClick={()=>saveData(formData)}
                        colorScheme="green"
                        variant="solid"
                        w="7rem">
                            Save
                        </Button>
                        </Flex>
                        {/* Add other buttons/actions as necessary */}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default EditDetailsModal;