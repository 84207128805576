import { React, useState } from 'react'
import {
    IconButton,
    Button,
    HStack,
    Stack,
    Image,
    Box,
    useColorModeValue,
    useColorMode,
    Flex,
    Text,
    Switch,
    Divider,
    Heading,
    Select,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    VStack
} from '@chakra-ui/react';
import { EmailIcon, MoonIcon, SunIcon, SettingsIcon } from '@chakra-ui/icons';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from "@chakra-ui/react"
import { useToast } from "@chakra-ui/react";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react"
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useHelp } from "./HelpContext";
import { useApi } from "./ApiContext";
import { HamburgerIcon } from '@chakra-ui/icons'; // Add this import for Hamburger and Close icons

const Links = ['Home', 'Create Resume', 'Create Cover Letter', 'Import Resume']
const hrefs = {
    'Home': '/',
    'Create Resume': '/resume',
    'Create Cover Letter': '/cover-letter',
    'Import Resume': '/import'
}

const NavLink = (props) => {
    const { children, href } = props;
    return (
      <Box
        as="a"
        px={2}
        py={1}
        rounded={'md'}
        _hover={{
          textDecoration: 'none',
          bg: useColorModeValue('gray.200', 'gray.700'),
        }}
        href={href}
      >
        {children}
      </Box>
    )
  }

// settings modal to clear the local storage
const SettingsModal = ({ isOpen, onClose }) => {
    const { showHelp, setShowHelp } = useHelp();
    const [show, setShow] = useState(false);
    const { apiKey, setApiKey } = useApi();
    const [tempApiKey, setTempApiKey] = useState(apiKey); // Temporary state variable
    const handleClick = () => setShow(!show);
    const handleSave = () => {
      setApiKey(tempApiKey);
      toast({ title: "API key saved.", status: "success" });
    }
    const toast = useToast();
    const handleClear = () => {
        localStorage.clear();
        toast({ title: "Local storage cleared.", status: "success" });
        onClose();
    }
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Settings</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                      <Flex
                        flexDirection={["column", "row"]}
                        justifyContent="space-between"
                        alignItems={["initial", "center"]}
                        gap={["0", "10px"]}
                      >
                        <Box flexGrow={1}>
                          <Heading as="h3" size="s">
                            Clear Local Storage
                          </Heading>
                          <Text mt='1'>Clear the local storage of your current browser session.</Text>
                          <Text mb='4' mt='2' fontSize="sm" color="gray.500">
                            *Note: This will reset all your current settings, resume/cover letter data and remove the api key from the storage.
                          </Text>
                        </Box>
                        <Flex justifyContent="flex-end">
                          <Button width={'80px'} colorScheme="red" onClick={handleClear}> Clear </Button>
                        </Flex>
                      </Flex>
                    </Box>
                    <Divider my='4' borderColor="gray.300" />
                    <Box>
                      <Flex
                        flexDirection={["column", "row"]}
                        justifyContent="space-between"
                        alignItems={["initial", "center"]}
                        gap={["0", "10px"]}
                      >
                        <Box flexGrow={1}>
                          <Heading as="h3" size="s">
                            Language
                          </Heading>
                          <Text mt='1'>Choose desired language</Text>
                          <Text mb='4' mt='2' fontSize="sm" color="gray.500">
                            *Note: If you cannot find your language, you can send us a feedback using the Contact Us button on the top right corner or Live Chat at the bottom right corner.
                          </Text>
                        </Box>

                        <Flex width={'full'} justifyContent="flex-end">
                          <Select
                            defaultValue="en"
                            marginLeft={["0", "2"]}
                            paddingBottom="1"
                            variant="outline"
                          >
                            <option selected value="en">
                              English
                            </option>
                            <option value="zh-CN">中文 (CN)</option>
                            <option value="ko-KR">한국어 (KR)</option>
                            <option value="ru-RU">Pусский (RU)</option>
                            <option value="ja-JP">日本語 (JP)</option>
                          </Select>
                        </Flex>
                      </Flex>
                    </Box>
                    {/* Change current apiKey from the storage */}
                    <Divider my='4' borderColor="gray.300" />
                    <Box>
                      <Flex
                        flexDirection={["column", "row"]}
                        justifyContent="space-between"
                        alignItems={["initial", "center"]}
                        gap={["0", "10px"]}
                      >
                        <Box flexGrow={1}>
                          <Heading as="h3" size="s">
                            OpenAI API Key
                          </Heading>
                          <Text mt='1'>Change the current openai api key</Text>
                          <Text mb='4' mt='2' fontSize="sm" color="gray.500">
                            *Note: This will change your stored api key in the local storage.
                          </Text>
                        </Box>
                          <Flex justifyContent={{ base: 'space-between', md: 'flex-start' }} align={'end'} flexDirection={{base: 'row', md:'column'}}>
                            <InputGroup maxWidth={'200px'}>
                              <Input
                                  autoComplete="off"
                                  type={show ? "text" : "password"}
                                  placeholder="OpenAI api key"
                                  value={tempApiKey}
                                  onChange={e => setTempApiKey(e.target.value)}
                              />
                              <InputRightElement>
                                  <IconButton 
                                  size="sm"
                                  icon={show ? <ViewOffIcon /> : <ViewIcon />}
                                  aria-label={show ? "Hide api" : "Show api"}
                                  onClick={handleClick}
                                  />
                              </InputRightElement>
                            </InputGroup>
                            <Button mt={{base: 0, md: 4}} width={'80px'} ml={4} colorScheme="red" onClick={handleSave}> Save </Button>
                          </Flex>
                        </Flex>
                    </Box>
                    <Divider my='4' borderColor="gray.300" />
                    <Box>
                      <Flex
                        flexDirection={["column", "row"]}
                        justifyContent="space-between"
                        alignItems={["initial", "center"]}
                        gap={["0", "10px"]}
                      >
                        <Box flexGrow={1}>
                          <Heading as="h3" size="s">
                            Show Help Modal
                          </Heading>
                          <Text mt='1'>Show help modal on cover letter creation.</Text>
                          <Text mb='4' mt='2' fontSize="sm" color="gray.500">
                          *Note: Disabling this setting will prevent the assistance modal from displaying when you're creating a cover letter, which guides you in obtaining your API key from the OpenAI website.
                          </Text>
                        </Box>
                        <Flex flexDirection={'column'} justifyContent="flex-end">
                          <Flex justifyContent="flex-end" mt="2">
                            <Switch 
                              id="switch" 
                              colorScheme="green" 
                              size="md" 
                              isChecked={showHelp}
                              onChange={(e) => {
                                  const newValue = e.target.checked;
                                  setShowHelp(newValue);
                              }}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

function NavBar() {
    const { colorMode, toggleColorMode } = useColorMode();
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    const handleLogoClick = () => {
        window.location.href = '/';
    }

    return (
      <Stack bg={useColorModeValue('gray.50', 'gray.900')} zIndex={10} position={'sticky'} top={0} p={'16px'}>
        <SettingsModal isOpen={isOpen} onClose={onClose} />
        {/* Mobile & Tablet View */}
        <Flex direction={'row'} align={'center'} justify={'space-between'} display={{base: 'flex', md: 'none'}}>
          <IconButton icon={<HamburgerIcon />} onClick={openDrawer} aria-label="Open Menu" />
          {/* <Image boxSize="50px" src="https://easy-resume.net/logo.png" alt="Logo" mx="auto" /> */}
          <Stack direction={'row'}>
            <IconButton icon={<EmailIcon />} aria-label="Contact" onClick={() => (window.location.href = 'mailto:info@easy-resume.net?subject=Feedback')} />
            <IconButton onClick={toggleColorMode} aria-label="Color Mode" icon={colorMode === 'dark' ? <SunIcon /> : <MoonIcon />} />
            <IconButton onClick={() => setIsOpen(true)} aria-label="Settings" icon={<SettingsIcon />} />
          </Stack>
        </Flex>
  
        {/* Drawer for Mobile & Tablet View */}
        <Drawer isOpen={isDrawerOpen} onClose={closeDrawer} placement="left">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">Menu</DrawerHeader>
            <DrawerBody>
              <VStack spacing={4} align="start">
                {Links.map((link) => (
                  <NavLink key={link} href={hrefs[link]}>{link}</NavLink>
                ))}
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
  
        {/* Desktop View */}
        <Flex direction={'row'} align={'center'} justify={'space-between'} display={{base: 'none', md: 'flex'}}>
          <Flex direction={'row'} align={'center'}>
            <Image onClick={handleLogoClick} style={{cursor: 'pointer'}} boxSize="50px" src="https://easy-resume.net/logo.png" alt="Logo" />
            <HStack as={'nav'} spacing={4} ml={4}>
              {Links.map((link) => (
                <NavLink key={link} href={hrefs[link]}>{link}</NavLink>
              ))}
            </HStack>
          </Flex>
          <Stack direction={'row'}>
            <Button leftIcon={<EmailIcon />} variant="outline" onClick={() => (window.location.href = '/contact-us')}>Contact Us</Button>
            <IconButton onClick={toggleColorMode} aria-label="Color Mode" icon={colorMode === 'dark' ? <SunIcon /> : <MoonIcon />} />
            <IconButton onClick={() => setIsOpen(true)} aria-label="Settings" icon={<SettingsIcon />} />
          </Stack>
        </Flex>
      </Stack>
    );
}

export default NavBar;