import React, { createContext, useContext, useState, useEffect } from 'react';

export const HelpContext = createContext();

export const HelpProvider = ({ children }) => {
  const [showHelp, setShowHelp] = useState(
    localStorage.getItem("showHelp") === "false" ? false : true
  );

  useEffect(() => {
    localStorage.setItem("showHelp", showHelp);
  }, [showHelp]);

  const value = {
    showHelp,
    setShowHelp,
  };

  return <HelpContext.Provider value={value}>{children}</HelpContext.Provider>;
};

export const useHelp = () => {
  const context = useContext(HelpContext);
  if (!context) {
    throw new Error('useHelp must be used within a HelpProvider');
  }
  return context;
};