import { React, useState } from "react";
import {
    HStack,
    Box,
    Button,
    Heading,
    Flex,
    FormControl,
    Spinner,
    FormLabel,
    Input,
    Select,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Text
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import OpenAIApiModal from "./components/OpenAIApiModal";
import { useNavigate, useHistory } from "react-router-dom";
import TagInput from "./components/TagInput";
import { Helmet } from "react-helmet";
import { useColorMode } from "@chakra-ui/react";
import { useApi } from "./components/ApiContext";
import { useCoverLetter } from "./components/CoverLetterContext";
import { useHelp } from "./components/HelpContext";

const steps = [
    { title: 'First Step', description: 'Personal Information' },
    { title: 'Second Step', description: 'Application Information' },
    { title: 'Third Step', description: 'Cover Letter Content' },
    { title: 'Fourth Step', description: 'Customization' },
  ]
  
  export const StepIndicator2 = ({ activeStep }) => {
    const { colorMode, toggleColorMode } = useColorMode();
    const renderCircle = (number, isActive) => (
      <Box
        key={number}
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={isActive ? "40px" : "20px"}
        height={isActive ? "40px" : "20px"}
        borderRadius="50%"
        border="2px solid"
        borderColor={isActive ? "blue.500" : "gray.400"}
        // if dark mode bg is black, if not bg is white
        bg={colorMode === "dark" ? "black" : "white"}
        color={isActive ? "blue.500" : "gray.400"}
        fontSize={isActive ? "lg" : "sm"}
        fontWeight="bold"
      >
        <Text>{number}</Text>
      </Box>
    );
  
    return (
      <HStack display={{base: 'flex', md: 'none'}} alignItems="center" justifyContent={'center'} spacing="4">
        <HStack spacing="1">
          <Box height="2px" width="40px" bg="gray.400" />
          {/* calculate how much circle will be in the left with substracting 1 from activeStep, then use mapping. Also if activeStep is 5, then all of the circles will be in the left */}
            {activeStep === 5 ? (
                <>
                {renderCircle(1, true)}
                {renderCircle(2, true)}
                {renderCircle(3, true)}
                {renderCircle(4, true)}
                </>
            ) : (
                [...Array(activeStep - 1)].map((_, index) =>
                renderCircle(index + 1, false)
                )
            )}
        </HStack>
        {renderCircle(activeStep, true)}
        <HStack spacing="1">
            {/* calculate how much circle will be in the right with substracting activeStep from 4, then use mapping. If activeStep is 5, none of them will be in the right */}
            {activeStep === 5 ? (
                <>
                </>
            ) : (
                [...Array(4 - activeStep)].map((_, index) =>
                renderCircle(activeStep + index + 1, false)
                )
            )}
            
          <Box height="2px" width="40px" bg="gray.400" />
        </HStack>
      </HStack>
    );
  };  

export function StepperBox({activeStep}) {
return (
    <>
        <StepIndicator2 activeStep={activeStep+1}/>
        <Stepper display={{base: 'none', md: 'flex'}} size={'md'} index={activeStep}>
        {steps.map((step, index) => (
            <Step key={index}>
            <StepIndicator>
                <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
                />
            </StepIndicator>

            <Box flexShrink='0'>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
            </Step>
        ))}
        </Stepper>
    </>
)
}

export const Form1 = ({marginTop = '32px', marginBottom = '32px', isError, formData, handleInputChange }) => {
    return(
        <>
        <Heading as="h2" size={{base: 'md', md: 'lg'}} textAlign="center" mt={marginTop} mb={{base: "0px" ,md: marginBottom}}>
            Personal Information
        </Heading>
        <Flex flexDirection={{base: 'column', md: 'row'}}>
            <FormControl mt={{base: '32px', md: '0px'}} isInvalid={isError.fullName} isRequired mr={'32px'}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Full Name</FormLabel>
                <Input value={formData.fullName} onChange={handleInputChange('fullName')} placeholder="John Doe" />
            </FormControl>
            <FormControl mt={{base: '32px', md: '0px'}} isInvalid={isError.email} isRequired>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Email Address</FormLabel>
                <Input type="email" value={formData.email} onChange={handleInputChange('email')} placeholder="johndoe@email.com" />
            </FormControl>
        </Flex>
        <Flex flexDirection={{base: 'column', md: 'row'}} mt={{base: '0px', md: '32px'}}>
            <FormControl mt={{base: '32px', md: '0px'}}  mr={'32px'}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Phone Number</FormLabel>
                <Input type="tel" value={formData.phoneNumber} onChange={handleInputChange('phoneNumber')} placeholder="(555) 123-4567" />
            </FormControl>
            <FormControl mt={{base: '32px', md: '0px'}}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Address</FormLabel>
                <Input value={formData.address} onChange={handleInputChange('address')} placeholder="123 Elm St, City, Country" />
            </FormControl>
        </Flex>
        <Flex flexDirection={{base: 'column', md: 'row'}} mt={{base: '0px', md: '32px'}}>
            <FormControl mt={{base: '32px', md: '0px'}} mr={{base: '0px', md: '32px'}}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>LinkedIn Profile</FormLabel>
                <Input type="url" value={formData.linkedInProfile} onChange={handleInputChange('linkedInProfile')} placeholder="linkedin.com/in/johndoe" />
            </FormControl>
            <FormControl mt={{base: '32px', md: '0px'}}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Personal Website/Blog</FormLabel>
                <Input type="url" value={formData.personalWebsite} onChange={handleInputChange('personalWebsite')} placeholder="johndoe.com" />
            </FormControl>
        </Flex>
            </>
    )
}

export const Form2 = ({marginTop = '32px', marginBottom = '32px', isError, formData, handleInputChange }) => {
    return(
        <>
        <Heading as="h2" size={{base: 'md', md: 'lg'}} textAlign="center" mt={marginTop} mb={{base: "0px" ,md: marginBottom}}>
        Application Information
        </Heading>
        <Flex flexDirection={{base: 'column', md: 'row'}}>
            <FormControl mt={{base: '32px', md: '0px'}} isInvalid={isError.companyName} isRequired mr={'32px'}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Company Name</FormLabel>
                <Input value={formData.companyName} onChange={handleInputChange('companyName')} placeholder="ABC Corp" />
            </FormControl>
        </Flex>
        <Flex flexDirection={{base: 'column', md: 'row'}} mt={{base: '0px', md: '32px'}}>
             <FormControl mt={{base: '32px', md: '0px'}} isInvalid={isError.positionApplied} isRequired>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Position Applied For</FormLabel>
                <Input value={formData.positionApplied} onChange={handleInputChange('positionApplied')} placeholder="Software Engineer" />
            </FormControl>
        </Flex>
        <Flex flexDirection={{base: 'column', md: 'row'}} mt={{base: '0px', md: '32px'}}>
            <FormControl mt={{base: '32px', md: '0px'}} mr={'32px'}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Company Address</FormLabel>
                <Input value={formData.companyAddress} onChange={handleInputChange('companyAddress')} placeholder="123 Elm St, City, Country" />
            </FormControl>
            <FormControl mt={{base: '32px', md: '0px'}}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Application Date</FormLabel>
                <Input value={formData.applicationDate} onChange={handleInputChange('applicationDate')} type="date" placeholder="MM/DD/YYYY" />
            </FormControl>
        </Flex>
            </>
    )
}

export const Form3 = ({marginTop = '32px', marginBottom = '32px', formData, handleInputChange}) => {
    return(
        <>
        <Heading as="h2" size={{base: 'md', md: 'lg'}} textAlign="center" mt={marginTop} mb={{base: "0px" ,md: marginBottom}}>
        Cover Letter Content
        </Heading>
        <Flex flexDirection={{base: 'column', md: 'row'}}>
            <FormControl mt={{base: '32px', md: '0px'}} mr={'32px'}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Knowledge About the Position</FormLabel>
                <Select defaultValue="your website" onChange={handleInputChange('knowledgeAboutPosition')} maxH={'100px'} overflowY={'auto'} variant='outline'>
                    <option value="your website">Company Website</option>
                    <option value="colleague">Colleague</option>
                    <option value="job board">Job Board</option>
                    <option value="industry event">Industry Event</option>
                    <option value="university career fair">University Career Fair</option>
                    <option value="social media">Social Media</option>
                    <option value="company presentation">Company Presentation</option>
                    <option value="recruiter">Recruiter</option>
                    <option value="networking event">Networking Event</option>
                    <option value="online advertisement">Online Advertisement</option>
                    <option value="newspaper advertisement">Newspaper Advertisement</option>
                    <option value="industry magazine">Industry Magazine</option>
                    <option value="referral">Referral</option>
                    <option value="friend">Friend</option>
                    <option value="internal posting">Internal Posting</option>
                    <option value="seminar or workshop">Seminar Or Workshop</option>
                    <option value="company newsletter">Company Newsletter</option>
                    <option value="webinar">Webinar</option>
                    <option value="alumni association">Alumni Association</option>
                    <option value="direct company outreach">Direct Company Outreach</option>
                    <option value="Other">Other</option>
                </Select>
                {/* <Input value={formData.knowledgeAboutPosition} onChange={handleInputChange('knowledgeAboutPosition')} placeholder="Familiar with Java, keen to join..." /> */}
            </FormControl>
            <FormControl mt={{base: '32px', md: '0px'}}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>References</FormLabel>
                <TagInput tags={formData.references} onChange={handleInputChange('references')} placeholder="Jane Smith, previous manager"/>
            </FormControl>
        </Flex>
        <Flex flexDirection={{base: 'column', md: 'row'}} mt={{base: '0px', md: '32px'}}>
            <FormControl mt={{base: '32px', md: '0px'}} mr={'32px'}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Experiences</FormLabel>
                <TagInput tags={formData.experiences} onChange={handleInputChange('experiences')} placeholder="Intern at XYZ, led a team..."/>
            </FormControl>
            <FormControl mt={{base: '32px', md: '0px'}}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Skills and Abilities</FormLabel>
                <TagInput tags={formData.skillsAndAbilities} onChange={handleInputChange('skillsAndAbilities')} placeholder="Javascript, Python, leadership"/>
            </FormControl>
        </Flex>
        <Flex flexDirection={{base: 'column', md: 'row'}} mt={{base: '0px', md: '32px'}}>
            <FormControl mt={{base: '32px', md: '0px'}} mr={'32px'}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Educational Background</FormLabel>
                <Input value={formData.educationalBackground} onChange={handleInputChange('educationalBackground')} placeholder="B.S. in Computer Science, XYZ University" />
            </FormControl>
        </Flex>
            </>
    )
}

export const Form4 = ({marginTop = '32px', marginBottom = '32px', formData, handleInputChange }) => {
    return(
        <>
        <Heading as="h2" size={{base: 'md', md: 'lg'}} textAlign="center" mt={marginTop} mb={{base: "0px" ,md: marginBottom}}>
        Customization
        </Heading>
        <Flex flexDirection={{base: 'column', md: 'row'}}>
            <FormControl mt={{base: '32px', md: '0px'}} mr={'32px'}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Preferred Salutation</FormLabel>
                <Select defaultValue="Dear Hiring Manager" onChange={handleInputChange('preferredSalutation')} maxH={'100px'} overflowY={'auto'} variant='outline'>
                    <option value="Dear Hiring Manager">Dear Hiring Manager</option>
                    <option value="Dear Hiring Team">Dear Hiring Team</option>
                    <option value="Dear Hiring Committee">Dear Hiring Committee</option>
                    <option value="Greetings to Hiring Team">Greetings to Hiring Team</option>
                    <option value="Greetings to Talent Acquisition Team">Greetings to Talent Acquisition Team</option>
                    <option value="To the team>">To the team</option>
                    <option value="To the Hiring Manager>">To the Hiring Manager</option>
                    <option value="Attention: Talent Acquisition Specialist>">Attention: Talent Acquisition Specialist</option>
                    <option value="Attention: Hiring Manager>">Attention: Hiring Manager</option>
                    <option value="Good Day Staffing Team">Good Day Staffing Team</option>
                    <option value="Good Day Talent Acquisition Team">Good Day Talent Acquisition Team</option>
                    <option value="Good Day Recruitment Team">Good Day Recruitment Team</option>
                </Select>
            </FormControl>
        </Flex>
        <Flex flexDirection={{base: 'column', md: 'row'}} mt={{base: '0px', md: '32px'}}>
            <FormControl mt={{base: '32px', md: '0px'}} mr={'32px'}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Special Message or Additional Information</FormLabel>
                <Input value={formData.specialMessage} onChange={handleInputChange('specialMessage')} placeholder="Eager to contribute to ABC's growth..." />
            </FormControl>
        </Flex>
        <Flex flexDirection={{base: 'column', md: 'row'}} mt={{base: '0px', md: '32px'}}>
            <FormControl mt={{base: '32px', md: '0px'}} mr={'32px'}>
                <FormLabel fontSize={{base: 'sm', md: 'md'}}>Template</FormLabel>
                <Select defaultValue="Professional" onChange={handleInputChange('designTemplate')} maxH={'100px'} overflowY={'auto'} variant='outline'>
                    <option value="Professional">Professional</option>
                    <option value="Modern">Modern</option>
                    <option value="Elegant">Elegant</option>
                    <option value="Classic">Classic</option>
                    <option value="Minimalist">Minimalist</option>
                    <option value="Bold">Bold</option>
                    <option value="Narrative">Narrative</option>
                    <option value="Precision">Precision</option>
                    <option value="ProActive">Pro-Active</option>
                    <option value="Refined">Refined</option>
                    <option value="Streamlined">Streamlined</option>
                </Select>
                {/* <Input value={formData.designTemplate} onChange={handleInputChange('designTemplate')} placeholder="Classic Blue" /> */}
            </FormControl>
        </Flex>
        </>
    )
}
            


function CoverLetter(){
    const [isModalOpen, setModalOpen] = useState(false);
    const toast = useToast();
    const { showHelp } = useHelp();
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const { apiKey, setApiKey } = useApi();
    const navigate = useNavigate();
    const { coverLetter, setCoverLetter } = useCoverLetter();
    const handleProceed = async (api) => {
        // check formData and if select inputs are empty fill them with default value
        if(formData.knowledgeAboutPosition===""){
            formData.knowledgeAboutPosition = "your website";
        }
        if(formData.designTemplate===""){
            formData.designTemplate = "Professional";
        }
        if(formData.preferredSalutation===""){
            formData.preferredSalutation = "Dear Hiring Manager";
        }
        if(api === "no-api"){
            setLoading(true);
            try {
                const response = await fetch('https://editor.easy-resume.net/cover-letter', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({api, formData}),
                });
        
                const data = await response.json();
        
                if (data.coverLetter) {
                    setStep(5);
                    setModalOpen(false);
                    setCoverLetter(data.coverLetter);
                    navigate('/cover-letter-editor', { state: {formData: formData} });
                } else {
                    toast({
                        title: "Something went wrong.",
                        description: "Please try again later.",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
        
            } catch (error) {
                toast({
                    title: "Network Error.",
                    description: "Please check your internet connection.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        }
        else{
            setLoading(true);
            try {
                const response = await fetch('https://editor.easy-resume.net/cover-letter', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({api, formData}),
                });
        
                const data = await response.json();
        
                if (data.coverLetter) {
                    setStep(5);
                    setModalOpen(false);
                    setApiKey(api);
                    setCoverLetter(data.coverLetter);
                    navigate('/cover-letter-editor', { state: {formData: formData} });
                } else {
                    toast({
                        title: "Invalid API Key.",
                        description: "Please check your API key and try again.",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
        
            } catch (error) {
                toast({
                    title: "Network Error.",
                    description: "Please check your internet connection.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        }
    };    
    const handleNextClick = () => {
        if (step === 1 && (!formData.fullName || !formData.email)) {
            setErrorData(prev => ({ ...prev, fullName: !formData.fullName, email: !formData.email }));
            return;
        }
        if (step === 2 && (!formData.companyName || !formData.positionApplied)) {
            setErrorData(prev => ({ ...prev, companyName: !formData.companyName, positionApplied: !formData.positionApplied }));
            return;
        }
        // Add checks for other steps if needed
        setStep(step + 1);  // If no validation errors, proceed to next step
    };    
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phoneNumber: "",
        address: "",
        linkedInProfile: "",
        personalWebsite: "",
        companyName: "",
        companyAddress: "",
        positionApplied: "",
        applicationDate: "",
        knowledgeAboutPosition: "",
        references: [],
        experiences: [],
        skillsAndAbilities: [],
        educationalBackground: "",
        preferredSalutation: "",
        specialMessage: "",
        designTemplate: ""
    });

    const [errorData, setErrorData] = useState({
        fullName: false,
        email: false,
        companyName: false,
        positionApplied: false
    });

    const handleInputChange = (field) => (event) => {
        if (field === 'references' || field === 'experiences' || field === 'skillsAndAbilities') {
            const newTags = event;
            setFormData(prevState => ({
                ...prevState,
                [field]: newTags
            }));
        }
        else{
            const { value } = event.target;
            setFormData(prevState => ({
                ...prevState,
                [field]: value
            }));
            
            // Reset the error state for the specific input if its value changes
            setErrorData(prevState => ({
                ...prevState,
                [field]: value ? false : true
            }));
        }
    };    
    return(
        <>
            <Helmet>
            <title>Easy Resume - Cover Letter Generator</title>
            <meta charset="UTF-8"/>
            <meta 
            name="description" 
            content="Create a professional cover letter in minutes with our free cover letter generator. AI-powered content generation supported."
            />
            <meta 
            name="keywords" 
            content="Cover Letter, Cover Letter Generator, Cover Letter Builder, Cover Letter Maker, Cover Letter Template, Cover Letter Example, Cover Letter Sample, Cover Letter Format, Cover Letter Writing, Cover Letter Tips, Cover Letter Advice, Cover Letter Help, Cover Letter Creator, Cover Letter Generator Free, Cover Letter Builder Free, Cover Letter Maker Free, Cover Letter Template Free, Cover Letter Example Free, Cover Letter Sample Free, Cover Letter Format Free, Cover Letter Writing Free, Cover Letter Tips Free, Cover Letter Advice Free, Cover Letter Help Free, Cover Letter Creator Free, Cover Letter Generator Online, Cover Letter Builder Online, Cover Letter Maker Online, Cover Letter Template Online, Cover Letter Example Online, Cover Letter Sample Online, Cover Letter Format Online, Cover Letter Writing Online, Cover Letter Tips Online, Cover Letter Advice Online, Cover Letter Help Online, Cover Letter Creator Online, AI Cover Letter, AI Cover Letter Generator, AI Cover Letter Builder, AI Cover Letter Maker, AI Cover Letter Template, AI Cover Letter Example, AI Cover Letter Sample, AI Cover Letter Format, AI Cover Letter Writing, AI Cover Letter Tips, AI Cover Letter Advice, AI Cover Letter Help, AI Cover Letter Creator, AI Cover Letter Generator Free, AI Cover Letter Builder Free, AI Cover Letter Maker Free, AI Cover Letter Template Free, AI Cover Letter Example Free, AI Cover Letter Sample Free, AI Cover Letter Format Free, AI Cover Letter Writing Free, AI Cover Letter Tips Free, AI Cover Letter Advice Free, AI Cover Letter Help Free, AI Cover Letter Creator Free, AI Cover Letter Generator Online, AI Cover Letter Builder Online, AI Cover Letter Maker Online, AI Cover Letter Template Online, AI Cover Letter Example Online, AI Cover Letter Sample Online, AI Cover Letter Format Online, AI Cover Letter Writing Online, AI Cover Letter Tips Online, AI Cover Letter Advice Online, AI Cover Letter Help Online, AI Cover Letter Creator Online"
            />
            <meta property="og:title" content="Easy Resume - Cover Letter Generator" />
            <meta 
            property="og:description"
            content="Create a professional cover letter in minutes with our free cover letter generator. AI-powered content generation supported."
            />

            <link rel="canonical" href="https://easy-resume.net/cover-letter" />
        </Helmet>
            {/* if showHelp is True then show modal, if not then run handleProceed function */}
            <OpenAIApiModal isOpen={isModalOpen} onClose={()=>{setModalOpen(false);
            setStep(4);}} handleProceed={handleProceed}/>
            {loading && (
                <Flex
                position="fixed"
                top="0"
                right="0"
                bottom="0"
                left="0"
                alignItems="center"
                justifyContent="center"
                zIndex={2000}
                bg="rgba(0,0,0,0.4)"
                >
                    <Flex flexDirection="column" alignItems="center">
                      <Spinner size={{base: 'lg', md: 'xl'}} />
                      <Text mt={8} color="white">Please be patient. This process may take 10 to 15 seconds.</Text> {/* Adjust the margin and color as necessary */}
                    </Flex>
                </Flex>
            )}
            <NavBar/>
            <Box
            borderWidth="1px"
            rounded="lg"
            shadow="1px 1px 3px rgba(0,0,0,0.3)"
            maxWidth={'62.5em'}
            p={8}
            m={{base: 5, md: "72px auto"}}
            as="form">
                    {/* show forms according to the state */}
                    {step === 1 ? <><StepperBox activeStep={0} /> <Form1 isError={errorData} handleInputChange={handleInputChange} formData={formData}/></> : null}
                    {step === 2 ? <><StepperBox activeStep={1} /> <Form2 isError={errorData} handleInputChange={handleInputChange} formData={formData}/></> : null}
                    {step === 3 ? <><StepperBox activeStep={2} /> <Form3 handleInputChange={handleInputChange} formData={formData}/></> : null}
                    {step === 4 ? <><StepperBox activeStep={3} /> <Form4 handleInputChange={handleInputChange} formData={formData}/></> : null}
                    {step === 5 ? <><StepperBox activeStep={4} /> <Form4 handleInputChange={handleInputChange} formData={formData}/></> : null}
                    {/* back and next button */}
                    <Flex w="100%" justifyContent="space-between">
            <Flex>
                <Button
                    mt={{base: '16px', md: '32px'}}
                    size={{base: 'sm', md: 'md'}}
                    onClick={() => {
                        setStep(step - 1)
                    }}
                    isDisabled={step === 1}
                    colorScheme="teal"
                    variant="solid"
                    w={{base: '6rem', md: '7rem'}}
                    mr="5%">
                    Back
                </Button>
                {step >= 4 ? (
                <Button
                    mt={{base: '16px', md: '32px'}}
                    size={{base: 'sm', md: 'md'}}
                    w={{base: '6rem', md: '7rem'}}
                    display={{base: 'none', md: 'block'}}
                    isDisabled={true}
                    colorScheme="teal"
                    variant="outline">
                    Next
                </Button>
                ) : 
                <Button
                    mt={{base: '16px', md: '32px'}}
                    size={{base: 'sm', md: 'md'}}
                    w={{base: '6rem', md: '7rem'}}
                    isDisabled={false}
                    onClick={handleNextClick}
                    colorScheme="teal"
                    variant="outline">
                    Next
                </Button>
                }
            </Flex>
                        {step >= 4 ? (
                        <Button
                            mt={{base: '16px', md: '32px'}}
                            size={{base: 'sm', md: 'md'}}
                            w={{base: '6rem', md: '7rem'}}
                            colorScheme="green"
                            variant="solid"
                            onClick={() => {
                                setStep(5);
                                if(showHelp){
                                    setModalOpen(true);
                                }
                                else{
                                    handleProceed("no-api");
                                }
                            }}>
                            Create
                        </Button>
                        ) : null}
                    </Flex>
            </Box>
            <Footer/>
        </>
    )
}

export default CoverLetter;