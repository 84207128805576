// import { ColorModeScript } from '@chakra-ui/react';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import Faq from './Faq';
import CoverLetter from './CoverLetter';
import Resume from './Resume';
import ErrorPage from './ErrorPage';
import ImportResume from './ImportResume';
import ContactUs from './ContactUs';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CoverLetterPage from './CoverLetterPage';
import Layout from './Layout';
import { HelpProvider } from './components/HelpContext';
import { ApiProvider } from './components/ApiContext';
import { CoverLetterProvider } from './components/CoverLetterContext';
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const breakpoints = {
  base: "0px",
  sm: "320px",
  md: "1000px",
  lg: "1200px",
  xl: "1400px",
  "2xl": "1536px",
};
const theme = extendTheme({ breakpoints });

root.render(
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <CoverLetterProvider>
        <ApiProvider>
          <HelpProvider>
            <Layout>
              <Routes>
                <Route
                  path="/"
                  element={<App />}
                />
                <Route
                  path="/resume"
                  element={<Resume />}
                />
                <Route
                  path="/cover-letter"
                  element={<CoverLetter />}
                />
                <Route
                  path="/faq"
                  element={<Faq />}
                />
                <Route
                  path="/import"
                  element={<ImportResume />}
                />
                <Route
                  path="/contact-us"
                  element={<ContactUs />}
                />
                <Route
                  path="/cover-letter-editor"
                  element={<CoverLetterPage />}
                />
                <Route
                  path="/error-page"
                  element={<ErrorPage />}
                />
              </Routes>
            </Layout>
          </HelpProvider>
        </ApiProvider>
      </CoverLetterProvider>
    </BrowserRouter>
  </ChakraProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
