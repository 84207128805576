import { Stack } from "@chakra-ui/react";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    AccordionIcon,
    Box,
    Flex,
} from "@chakra-ui/react";
import  {ReactComponent as FaqSvg} from './assets/faq.svg';

import { Helmet } from 'react-helmet';

function AccordionItemComponent(props) {
    return(
        <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box as="span" flex='1' textAlign='left'>
                                {props.title}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            {props.content}
                        </AccordionPanel>
                    </AccordionItem>
    )
}

function Faq() {
    return(
        <>
            <Helmet>
                <title>Easy Resume - FAQ</title>
                <meta charset="UTF-8"/>
                <meta 
                name="description" 
                content="FAQ page for Easy Resume"
                />
                <meta 
                name="keywords" 
                content="resume, easy, faq, frequently asked questions, questions, answers, help, support, resume help, resume support, resume questions, resume answers, resume faq, easy resume faq, easy resume questions, easy resume answers, easy resume help, easy resume support, easy resume frequently asked questions, easy resume help, easy resume support, easy resume questions, easy resume answers, easy resume faq, easy resume frequently asked questions, easy resume questions, easy resume answers, easy resume help, easy resume support, easy resume faq, easy resume frequently asked questions, easy resume questions, easy resume answers, easy resume help, easy resume support, easy resume faq, easy resume frequently asked questions, easy resume questions, easy resume answers, easy resume help, easy resume support, easy resume faq, easy resume frequently asked questions, easy resume questions, easy resume answers, easy resume help, easy resume support, easy resume faq, easy resume frequently asked questions"
                />
                <meta property="og:title" content="Easy Resume - FAQ"/>
                <meta 
                property="og:description"
                content="FAQ page for Easy Resume"
                />

                <link rel="canonical" href="https://easy-resume.net/faq" />
            </Helmet>
            <NavBar/>
            <Flex direction="column" alignItems="center" justifyContent="center" minH="80vh">
            <Stack alignItems={'center'}>
                <Stack maxWidth={'1280px'} direction={{ base: 'column', md: 'row' }} align={'center'}>
                    <Box width={'600px'} maxWidth={'100%'} height={'500px'} overflow={'auto'}>
                        <Stack width={'100%'} direction={'column'} textAlign={'left'}>
                            <Accordion allowMultiple>
                                <AccordionItemComponent title={'1. What is Easy Resume?'} content={'Easy Resume is an advanced web application that harnesses the power of artificial intelligence to help users craft professional resumes and cover letters with ease. Simply input your information, and use AI to make it generate detailed content for you! We also offer options for users to create their resumes or cover letters without the assistance of AI.'}/>
                                <AccordionItemComponent title={'2. How does the AI-powered generation work?'} content={'Our system analyzes the data you provide, such as your work experience, skills, and education. Using this data, AI creates content that matches professional standards and current industry trends, ensuring your resume stands out in the best way.'}/>
                                <AccordionItemComponent title={'3. How customizable is the AI-generated content?'} content={'While the AI does a fantastic job of generating quality content, we understand the importance of personal touch. Users can freely edit and make changes to any section of their resume or cover letter to tailor it to their specific needs.'}/>
                                <AccordionItemComponent title={'4. Is it free to use?'} content={'Yes! Easy Resume operates on a freemium model. While most of its features are available for free, certain services associated with extensive work and cost (such as downloading your resume in DOCX format) require a small fee. All other functionalities are provided at no charge.'}/>
                                <AccordionItemComponent title={'5. Why do you require api key to use AI?'} content={'The sole revenue stream for Easy Resume comes from the DOCX download feature, which provides us with minimal earnings. Currently, these earnings just suffice to cover our operational costs. Implementing the OpenAI API key feature for free would incur expenses that are untenable for us at this stage. We understand this might be inconvenient, but we kindly ask for your patience as we grow. In the meantime, we are working towards developing a free-to-use AI model.'}/>
                                <AccordionItemComponent title={'6. How do I download my resume and cover letter?'} content={'Once you\'re satisfied with your resume and cover letter, you can easily download them in either PDF, TXT or DOCX format. Simply click the \'Download\' button and choose your preferred format.'}/>
                                <AccordionItemComponent title={'7. Are my personal details and data safe with Easy Resume?'} content={'Absolutely. Your privacy is our top priority. We implement rigorous security measures to ensure your personal details and data are protected. We never share or sell your data to third parties.'}/>
                                <AccordionItemComponent title={'8. What if I have no prior work experience?'} content={'No worries! Easy Resume is designed for everyone, including fresh graduates and those just starting out. AI can highlight your skills, education, and other relevant experiences to create a compelling resume.'}/>
                                <AccordionItemComponent title={'9. Can I use the same resume for different job applications?'} content={'While the AI generates a robust and versatile resume, it\'s always a good idea to customize your resume slightly for each specific job application. Easy Resume makes this process quick and simple.'}/>
                                <AccordionItemComponent title={'10. How up-to-date are the templates and formats?'} content={'We constantly update our database with the latest industry trends and best practices. This ensures that your resume and cover letter always match what employers are looking for.'}/>
                                <AccordionItemComponent title={'11. I have feedback or encountered an issue. How can I get in touch?'} content={'We value your feedback and are here to help! If you have any concerns, feedback, or issues, please reach out to our customer support team at info@easy-resume.net or clicking the contact-us button at the top.'} />
                </Accordion>
                        </Stack>
                    </Box>
                    <Box>
                        <FaqSvg />
                    </Box>
                </Stack>
            </Stack>
        </Flex>
            <Footer/>
        </>
    )
}

export default Faq;