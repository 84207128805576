import React, { useState, useEffect } from 'react';
import { Button, ModalFooter, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Flex, Text } from '@chakra-ui/react';
import { AddIcon, EditIcon } from '@chakra-ui/icons';

export default function ProfilesModal({ editData, setMainFormData, isOpen, onClose, isEditMode }) {
  const initialFormData = {
    network: 'LinkedIn',
    username: '',
    url: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isInvalid, setIsInvalid] = useState(true);

  useEffect(() => {
    const newFormData = isEditMode && editData ? editData : initialFormData;
    setFormData(newFormData);
    setIsInvalid(isEditMode ? false : !newFormData.username);
  }, [isEditMode, editData]);

  const handleChange = e => {
    const newFormData = { ...formData, [e.target.name]: e.target.value };
    setFormData(newFormData);
    setIsInvalid(!newFormData.username);
  };

  const handleSubmit = () => {
    if (!isInvalid) {
      setMainFormData(prev => ({ ...prev, profiles: isEditMode ? prev.profiles.map(p => p === editData ? formData : p) : [...prev.profiles, formData] }));
      onClose();
    }
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex alignItems="center">
            {isEditMode ? <EditIcon boxSize={4} /> : <AddIcon boxSize={4} />}
            <Text ml="2">{isEditMode ? 'Edit Profile' : 'Add Profile'}</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"row"} mt={4}>
            <FormControl isRequired>
              <FormLabel>Network</FormLabel>
              <Select name="network" value={formData.network || 'LinkedIn'} onChange={handleChange}>
                <option value="Behance">Behance</option>
                <option value="Discord">Discord</option>
                <option value="Codeberg">Codeberg</option>
                <option value="Codechef">Codechef</option>
                <option value="Codeforces">Codeforces</option>
                <option value="Dribbble">Dribbble</option>
                <option value="Facebook">Facebook</option>
                <option value="GeeksforGeeks">GeeksforGeeks</option>
                <option value="Github">Github</option>
                <option value="Gitlab">Gitlab</option>
                <option value="HackerEarth">HackerEarth</option>
                <option value="HackerRank">HackerRank</option>
                <option value="Instagram">Instagram</option>
                <option value="LeetCode">LeetCode</option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="Mastodon">Mastodon</option>
                <option value="Medium">Medium</option>
                <option value="Skype">Skype</option>
                <option value="SoundCloud">SoundCloud</option>
                <option value="StackOverflow">StackOverflow</option>
                <option value="Telegram">Telegram</option>
                <option value="TopCoder">TopCoder</option>
                <option value="Twitter">Twitter</option>
                <option value="Xing">Xing</option>
                <option value="YouTube">YouTube</option>
                <option value="Other">Other</option>
              </Select>
            </FormControl>
            {renderInput('Username', 'username', true, 2)}
          </Flex>
          <Flex mt={4}>
            {renderInput('Website', 'url', false)}
            </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit} isDisabled={isInvalid}> {isEditMode ? 'Edit' : 'Add'}</Button>
          <Button variant="ghost" onClick={()=>onClose()}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  function renderInput(label, name, isRequired = false, ml = 0, type = 'text') {
    return (
      <FormControl isRequired={isRequired} ml={ml} isInvalid={isInvalid && isRequired && !formData[name]}>
        <FormLabel>{label}</FormLabel>
        <Input type={type} name={name} value={formData[name] || ''} onChange={handleChange} />
      </FormControl>
    );
  }
};