import React, { useState } from "react";
import {
  theme,
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  useToast,
  OrderedList,
  ListItem,
  Flex
} from "@chakra-ui/react";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

import { Helmet } from "react-helmet";

function ImportResume() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleFileUpload = async (event, isZip) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const url = isZip
      ? "https://api.easy-resume.net/import2"
      : "https://api.easy-resume.net/import";

    if (isZip && file.type !== "application/zip") {
      toast({ title: "Invalid file format. Please upload a .zip file.", status: "error" });
      return;
    }

    if (!isZip && file.type !== "application/json") {
      toast({ title: "Invalid file format. Please upload a .json file.", status: "error" });
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData
      });
      const data = await response.json();

      if (data.return_url) {
        window.open(data.return_url, "_blank");
        window.location.href = "/"; // Assuming root ("/") is the homepage
      } else {
        throw new Error("Invalid response from server.");
      }
    } catch (error) {
      toast({ title: error.message, status: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Easy Resume - Import Resume</title>
        <meta charset="UTF-8"/>
        <meta 
        name="description" 
        content="Import your resume from LinkedIn or upload your existing resume"
        />
        <meta 
        name="keywords" 
        content="resume, easy, contact, contact us, contact"
        />
        <meta property="og:title" content="Easy Resume - Import Resume"/>
        <meta 
        property="og:description"
        content="Import your resume from LinkedIn or upload your existing resume"
        />

        <link rel="canonical" href="https://easy-resume.net/import" />
    </Helmet>
      <NavBar />
      <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
        <Box m={{base: 5, md: 0}} p={5} shadow="md" borderWidth="1px">
          <Text fontSize={{base: 'lg', md:"xl"}} mb={4}>Import Your Resume</Text>
          <Text fontSize={{base: 'sm', md: 'md'}} maxWidth={'600px'} mb={3}>Welcome back! If you've been with us before, simply head to the 'Import Your Resume' area to upload your saved resume.
          <br/><br/>New here? No problem! You can use 'Import from LinkedIn' option to upload your LinkedIn resume or, if you're starting fresh, hit the gleaming "Create Resume" button above and let's craft something amazing together!<br/></Text>
          <Input id="fileInput" type="file" accept=".json" style={{ display: "none" }} onChange={e => handleFileUpload(e, false)} />
          <Flex alignItems="start">
            <Button size={{base: 'sm', md: 'md'}} mr={3} onClick={() => document.getElementById("fileInput").click()}>Upload Resume</Button>
            <Button size={{base: 'sm', md: 'md'}} onClick={() => setModalOpen(true)}>Import from LinkedIn</Button>
          </Flex>
        </Box>
      </Box>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={{base: 'lg', md: 'xl'}}>LinkedIn Resume Import</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={{base: 'sm', md: 'md'}} mb={3}>Here's how you can extract your resume as .zip file from LinkedIn:</Text>
            <Text fontSize={{base: 'sm', md: 'md'}} mb={3}>
              <OrderedList spacing={2}>
                <ListItem>Click the Me icon at the top of your Linkedin homepage.</ListItem>
                <ListItem>Select Settings & Privacy from the dropdown.</ListItem>
                <ListItem>Click the Data Privacy on the left rail.</ListItem>
                <ListItem>Under the How LinkedIn uses your data section, click Get a copy of your data.</ListItem>
                <ListItem>Select the data that you’re looking for and Request archive.</ListItem>
              </OrderedList>
            </Text>
            <Button mb={3} onClick={() => document.getElementById("zipInput").click()}>Upload LinkedIn Zip</Button>
            <Input id="zipInput" type="file" accept=".zip" style={{ display: "none" }} onChange={e => handleFileUpload(e, true)} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Footer />
    </>
  );
}

export default ImportResume;