// Layout.js
import React from 'react';

const Layout = ({ children }) => {
  React.useEffect(() => {
    // Tawk.to initialization code here
    const script = document.createElement("script");
    script.src = "https://embed.tawk.to/6508a38f0f2b18434fd93a4f/1hakrtqm2";
    script.async = true;
    script.charset = "UTF-8";
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);
  }, []);
  
  return <>{children}</>;
}

export default Layout;