import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { Box, Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import React, { useState } from 'react';

const ArrayInput = ({ value = [], label, onChange }) => {
  const [items, setItems] = useState(value);

  const onAdd = () => setItems([...items, '']);
  const onDelete = (index) => setItems(items.filter((_, idx) => idx !== index));
  const handleChange = (event, index) => {
    const newItems = [...items];
    newItems[index] = event.target.value;
    setItems(newItems);
    onChange(newItems);
  };

  return (
    <Box mb="4">
      <Box d="flex" alignItems="center" justifyContent="space-between" mb="4">
        <Box fontSize="lg" fontWeight="semibold">
          {label} ({items.length})
        </Box>
        <Button onClick={onAdd}>
          <AddIcon />
        </Button>
      </Box>
      <Box d="grid" gridTemplateColumns="repeat(2, 1fr)" gap="4">
        {items.map((item, index) => (
          <InputGroup key={index}>
            <Input value={item} onChange={(e) => handleChange(e, index)} />
            <InputRightElement>
              <Button variant="ghost" onClick={() => onDelete(index)}>
                <DeleteIcon color="red.500" />
              </Button>
            </InputRightElement>
          </InputGroup>
        ))}
      </Box>
    </Box>
  );
};

export default ArrayInput;