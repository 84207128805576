import React, { useState, useRef, useEffect } from 'react';
import {
  Box, Input, InputGroup, InputRightElement, Button, Tag, TagLabel,
  TagCloseButton, FormControl, FormErrorMessage
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

function TagInput({placeholder, onChange, tags: initialTags = []}) {
  const [tags, setTags] = useState([]);
  useEffect(() => {
    setTags(initialTags);
  }
  , [initialTags]);
  const [inputValue, setInputValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const inputRef = useRef(null);
  const [inputWidth, setInputWidth] = useState('0px');

  useEffect(() => {
    if (inputRef.current) {
      setInputWidth(`${inputRef.current.offsetWidth}px`);
    }
  }, []);

  const handleAddTag = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
      setInputValue('');
      setIsInvalid(false);
      onChange([...tags, inputValue]);
    } else {
      setIsInvalid(true);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setIsInvalid(false);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddTag();
    }
  };

  const handleRemoveTag = (tag) => {
    setTags(tags.filter(t => t !== tag));
    onChange(tags.filter(t => t !== tag));
  };

  return (
    <Box>
      <FormControl isInvalid={isInvalid}>
        <InputGroup>
          <Input
            ref={inputRef}
            placeholder={placeholder}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
          />
          <InputRightElement>
            <Button onClick={handleAddTag} size="sm">
              <AddIcon 
                transform="rotate(0deg)"
                transition="transform 0.2s ease-in-out"
                _hover={{ transform: "rotate(90deg)" }}
              />
            </Button>
          </InputRightElement>
        </InputGroup>
      
        <FormErrorMessage color={'orange'}>This tag already exists!</FormErrorMessage>
      </FormControl>

      <Box 
        mt={2} 
        // height={`${theme.sizes[7]}`} 
        overflowX="scroll" 
        maxWidth={inputWidth}
        css={{
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <Box display="flex">
          {tags.map(tag => (
            <Tag
              key={tag} 
              size="md" 
              borderRadius="full" 
              variant="solid" 
              colorScheme="green" 
              mr={2}
              minWidth="auto"
              flexShrink={0}
            >
              <TagLabel isTruncated={false}>
                {tag}
              </TagLabel>
              <TagCloseButton onClick={() => handleRemoveTag(tag)} />
            </Tag>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default TagInput;